import Api from '@/services/Api'

export default {
  getLoggedInUser (params) {
    return Api().get('/api/users/me', params)
  },
  getLawyer (params) {
    return Api().get('api/users/viewLawyer/' + params.lawyerId)
  },
  getReviews (params) {
    return Api().get(`api/users/me/reviews/${params.userId}?page=${params.page}`)
  },
  updateProfilePicture (params) {
    return Api().post('/api/users/upload-image', params.payload)
  },
  updatePassword (params) {
    return Api().put('/api/users/updatePassword', params)
  },
  updateInfo (params) {
    return Api().put('/api/users/updateInfo', params)
  },
  updatePhoneNumber (params) {
    return Api().put('/api/users/updatePhoneNumber', params)
  },
  updateCompleteAddress (params) {
    return Api().put('/api/users/updateCompleteAddress', params)
  },
  updateEducation (params) {
    return Api().put('/api/users/updateEducation', params)
      .catch(err => {
        if (err.response) {
          return err.response
        } else if (err.request) {
          return err.request
        } else {
          return err
        }
      })
  },
  loginGoogle () {
    return Api().get('/api/users/google')
  },
  loginFacebook () {
    return Api().get('/api/users/facebook')
  },
  callBackGoogle (params) {
    return Api().get(`/api/users/google-redirect?code=${params.code}&scope=${params.scope}&authuser=${params.authuser}`)
  },
  callBackFaceBook (params) {
    return Api().get(`/api/users/facebook-redirect?code=${params.code}`)
  },
  finalStepOAuthLawyer (params) {
    return Api().patch('/api/users/reg-final/lawyer', params)
  },
  finalStepOAuthClient (params) {
    return Api().patch('/api/users/reg-final/client', params)
  },
  addEducation (params) {
    return Api().patch('/api/users/addEducation', params)
  }
}
