import JobService from '../../services/JobService'
import moment from 'moment'

const state = {
  isFetching: false,
  isFetchingMoreJobs: false,
  viewedJob: false,
  runOnce: false,
  olds: [],
  jobs: [],
  page: 1,
  pages: 0,
  limit: 10,
  title: '',
  isAnonymous: '',
  date: moment(new Date()).subtract(7, 'days')
  
}

const getters = {
  jobs (state) {
    return state.jobs
  },
  viewedJob (state) {
    return state.viewedJob
  },
  isMaxPage (state) {
    if (state.pages === state.page) {
      return true
    }
  },
  isFetching (state) {
    return state.isFetching
  },
  isFetchingMoreJobs (state) {
    return state.isFetchingMoreJobs
  }
}

const mutations = {
  SET_JOBS (state, payload) {
    state.jobs = state.olds

    for (let i = 0; i < payload.docs.length; i++) {
      state.jobs.push(payload.docs[i])
    }

    // state.page = state.page + state.page
    state.pages = payload.pages
  },
  SET_REALTIME_JOBS (state, payload) {
    state.jobs.unshift(payload)
  },
  ADD_MORE (state, payload) {
    for (let i = 0; i < payload.length; i++) {
      state.jobs.push(payload[i])
    }
    state.page++
  },
  SET_TITLE_FILTER (state, payload) {
    state.page = 1
    state.olds = []
    state.jobs = []
    state.title = payload || ''
  },
  SET_ANONYMOUS_FILTER (state, payload) {
    state.isAnonymous = payload || false
    state.olds = []
    state.jobs = []
  },
  SET_OLD (state) {
    state.olds = state.jobs
  },
  SET_JOB_BIDS (state, payload) {
    for (let i = 0; i < state.jobs.length; i++) {
      if (state.jobs[i]._id === payload) {
        state.jobs[i].bids += 1
      }
    }
  },
  SET_CANCEL_JOB_BID (state, payload) {
    for (let i = 0; i < state.jobs.length; i++) {
      if (state.jobs[i]._id === payload) {
        state.jobs[i].bids -= 1
      }
    }
  },
  CHANGE_RUNONCE (state) {
    state.runOnce = true
  },
  CHANGE_VIEWEDJOB (state) {
    state.viewedJob = true
  },
  CHANGE_ISFETCHING (state) {
    state.isFetching = !state.isFetching
  },
  CHANGE_ISFETCHINGMOREJOBS (state) {
    state.isFetchingMoreJobs = !state.isFetchingMoreJobs
  },
  DONE_FETCHING (state) {
    state.olds = []
    state.page = 1
  }
}

const actions = {
  getJobs ({ commit, state }, payload) {
    if (state.jobs.length > 0) {
      commit('DONE_FETCHING')
    }
    commit('CHANGE_ISFETCHING')
    return new Promise((resolve, reject) => {
      JobService.getJobs({
        page: state.page,
        limit: state.limit,
        title: state.title,
        isAnonymous: state.isAnonymous,
        date: state.date._d
      })
        .then(res => {
          commit('SET_JOBS', res.data)
        })
        .finally(() => {
          commit('CHANGE_ISFETCHING')
        })
    })
  },
  setFilter ({ commit, state }, payload) {
    commit('SET_TITLE_FILTER', payload.jobTitleFilter || '')
    commit('SET_ANONYMOUS_FILTER', payload.isAnonymous)
    commit('CHANGE_ISFETCHINGMOREJOBS')

    return new Promise((resolve, reject) => {
      JobService.getJobs({
        page: state.page,
        limit: state.limit,
        title: state.title,
        isAnonymous: state.isAnonymous,
        date: state.date._d
      })
        .then(res => {
          commit('SET_JOBS', res.data)
          commit('CHANGE_ISFETCHINGMOREJOBS')
          resolve()
        })
    })
  },
  getMoreJobs ({ commit, state }, payload) {
    commit('CHANGE_ISFETCHINGMOREJOBS')
    return new Promise((resolve, reject) => {
      JobService.getJobs({
        page: state.page + 1,
        limit: state.limit,
        title: state.title,
        isAnonymous: state.isAnonymous
      })
        .then(res => {
          commit('ADD_MORE', res.data.docs)
          commit('CHANGE_ISFETCHINGMOREJOBS')
        })
    })
  },
  setRealTimeJobs ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('SET_REALTIME_JOBS', payload)
    })
  },
  setBidsJob ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('SET_JOB_BIDS', payload.jobId)
    })
  },
  setCancelJob ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('SET_CANCEL_JOB_BID', payload.jobId)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
