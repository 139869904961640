<template>
  <v-navigation-drawer
    v-model="drawer"
    clipped
    disable-resize-watcher
    app
  >
    <v-list v-if="loggedIn" class="pa-1 primary white--text">
      <v-list-item avatar :to="user.isLawyer ? '/overview' : ''">
        <v-list-item-avatar>
          <img :src="user.imageUrl">
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="white--text">{{ user.fullName }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list>
      <v-list-item v-for="(list, index) in lists" :key="index" :to="list.to">
        <v-list-item-content class="ml-2">
          <v-list-item-title>{{ list.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list>
        <v-btn
          v-if="!loggedIn"
          block
          depressed
          color="primary"
          to="/"
          class="px-5"
        >Home</v-btn>
        <v-btn
          v-else
          block
          depressed
          color="primary"
          :to="$store.getters['user/user'].user.isLawyer ? '/Jobs' : '/Post-Problem'"
          class="px-5"
        >Home</v-btn>
      </v-list>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data () {
    return {
      drawer: false,
      lists: [
        { to: '/how-it-works', name: 'How it Works' },
        { to: '/about-us', name: 'About Us' },
        { to: '/terms-and-conditions', name: 'Terms & Conditions' },
        { to: '/privacy-policy', name: 'Privacy Policy' },
        // { to: '/fees-and-charges', name: 'Fees & Charges' },
        // { to: '/cotact-us', name: 'Contact Us' }
      ]
    }
  },
  methods: {
    showSideBar () {
      this.drawer = !this.drawer
    }
  },
  computed: {
    user () {
      return this.$store.getters['user/user'].user
    },
    loggedIn () {
      return this.$store.getters['auth/loggedIn']
    }
  },
  filters: {
    slice: function (value) {
      if (!value) return ''
      if (/\s/.test(value)) {
        value = value.toString()
        return value.substr(0, value.indexOf(' '))
      } else {
        return value
      }
    }
  }
}
</script>
