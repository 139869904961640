var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"header",attrs:{"id":"app"}},[(this.$store.getters['user/user'].user.isLawyer === false | this.$store.getters['user/user'].user.isLawyer === true &&
    [
      'Register',
      'About-Us',
      'Contact-Us',
      'Fees-and-Charges',
      'How-It-Works',
      'Privacy-Policy',
      'Terms-And-Conditions',
      'How-To-Pay',
      'FinalStep'
    ].indexOf(_vm.$route.name) < 0)?_c('Toolbar'):_vm._e(),([
      'About-Us',
      'Contact-Us',
      'Fees-and-Charges',
      'How-It-Works',
      'Privacy-Policy',
      'Terms-And-Conditions',
      'How-To-Pay'
    ].indexOf(_vm.$route.name) >= 0)?_c('InformationToolbar'):_vm._e(),_c('v-main',{staticStyle:{"background-color":"#F0F2F5"}},[_c('v-container',{attrs:{"fluid":"","pa-0":""}},[(!_vm.$store.getters['user/user'].user.isVerified &&
        _vm.$store.getters['control/showPleaseVerify'] &&
        _vm.$store.getters['auth/loggedIn'] &&
        [
          'Register',
          'About-Us',
          'Contact-Us',
          'Fees-and-Charges',
          'How-It-Works',
          'Privacy-Policy',
          'Terms-And-Conditions',
          'How-To-Pay'
        ].indexOf(_vm.$route.name) < 0)?_c('PleaseVerify'):_vm._e(),(!_vm.$store.getters['errors/hasError500'])?_c('router-view'):_c('ErrorPage500')],1)],1),([ 'MobileConversation' ].indexOf(_vm.$route.name) < 0)?_c('Footer'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }