const state = {
  taskTabIndex: 0,
  registerTabIndex: 0,
  salesTabIndex: 'credits',
  showPleaseVerify: true
}

const getters = {
  taskTabIndex (state) {
    return state.taskTabIndex
  },
  registerTabIndex (state) {
    return state.registerTabIndex
  },
  salesTabIndex (state) {
    return state.salesTabIndex
  },
  showPleaseVerify (state, getters, rootState, rootGetters) {
    return state.showPleaseVerify
  }
}

const mutations = {
  SET_TASK_TAB_INDEX (state, payload) {
    state.taskTabIndex = payload
  },
  SET_REGISTER_TAB_INDEX (state, payload) {
    state.registerTabIndex = payload
  },
  SET_SHOW_PLEASE_VERIFY (state) {
    state.showPleaseVerify = false
  }
}

const actions = {
  SET_TASK_TAB_INDEX ({ commit }, payload) {
    commit('SET_TASK_TAB_INDEX', payload)
  },
  SET_REGISTER_TAB_INDEX ({ commit }, payload) {
    commit('SET_REGISTER_TAB_INDEX', payload)
  },
  changeShowPleaseVerify ({ commit }) {
    commit('SET_SHOW_PLEASE_VERIFY')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
