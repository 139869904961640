import ProposalService from '@/services/ProposalService'
var VueCookie = require('vue-cookie')

const state = {
  isLoading: false,
  laProposals: [],
  userProposals: {},
  singleProposalAtJob: null
}

const getters = {
  laProposals (state) {
    return state.laProposals
  },
  userProposals (state) {
    return state.userProposals
  },
  singleProposalAtJob (state) {
    return state.singleProposalAtJob
  },
  averageBidAmount (state) {
    let resultArray = []
    let average = arr => arr.reduce((a, v) => a + v, 0) / arr.length

    for (let key in state.laProposals) {
      if (!state.laProposals[key].isFree) {
        resultArray.push(state.laProposals[key].amount)
      }
    }

    return average(resultArray)
  },
  lawyerBidded (state) {
    return state.singleProposalAtJob != null ? true : false
  },
  isLoading (state) {
    return state.isLoading
  }
}

const mutations = {
  ADD_LA_PROPOSALS (state, payload) {
    state.laProposals.push(payload)
  },
  SET_LA_PROPOSALS (state, payload) {
    state.laProposals = payload.proposals
  },
  ADD_SINGLE_PROPOSAL_AT_JOB (state, payload) {
    state.singleProposalAtJob = payload
  },
  SET_SINGLE_PROPOSAL_AT_JOB (state, payload) {
    if (payload.proposal != null) {
      state.singleProposalAtJob = payload.proposal
      state.singleProposalAtJob.lawyer.reviews = payload.review
    } 
  },
  SET_USER_PROPOSALS (state, payload) {
    state.userProposals = payload
  },
  UPDATE_USER_PROPOSAL (state, payload) {
    for (var i = 0; i < state.userProposals.length; i++) {
      if (state.userProposals[i]._id === payload._id) {
        state.userProposals[i].message = payload.message
        state.userProposals[i].amount = +payload.amount // Added + for parsing into a Number
        state.userProposals[i].followUps = payload.followUps
      }
    }
  },
  DELETE_USER_PROPOSAL (state, payload) {
    if (payload.fromBidders) {
      state.laProposals.splice(payload.index, 1)
    } else {
      for (var i = 0; i < state.userProposals.length; i++) {
        if (state.userProposals[i]._id === payload) {
          state.userProposals.splice(i, 1)
        }
      }
    }
  },
  RETRACT_PROPOSAL_AT_BIDDERS (state, payload) {
    for (let i = 0; i < state.laProposals.length; i++) {
      if (state.laProposals[i]._id === payload) {
        state.singleProposalAtJob = null
        state.laProposals.splice(i, 1)
      }
    }
  },
  IS_LOADING (state, payload) {
    state.isLoading = payload
  },
  REMOVE_USER_PROPOSAL (state) {
    state.singleProposalAtJob = null
  }
}

const actions = {
  ADD_LA_PROPOSALS ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProposalService.postLAProposals({
        proposal: payload
      })
        .then(res => {
          commit('SET_LA_PROPOSALS', res.data)
          commit('ADD_SINGLE_PROPOSAL_AT_JOB', res.data.laProposal)
          commit('user/UPDATE_CREDITS', null, { root: true })
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // GET_LA_PROPOSALS ({ commit }, payload) {
  //   return new Promise((resolve, reject) => {
  //     ProposalService.getLAProposals({
  //       problemId: payload
  //     })
  //       .then(res => {
  //         console.log(res)
  //         resolve()
  //       })
  //       .catch(err => {
  //         reject(err)
  //       })
  //   })
  // },
  getLAProposals ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProposalService.getLAProposals({
        problemId: payload
      })
        .then(res => {
          commit('SET_LA_PROPOSALS', res.data)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getUserProposalAtJob ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProposalService.getMyProposal({ problemId: payload })
        .then(res => {
          commit('SET_SINGLE_PROPOSAL_AT_JOB', res.data)
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }) 
  },
  GET_USER_PROPOSALS ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('IS_LOADING', true)
      ProposalService.getMyProposals({
        id: payload
      })
        .then(res => {
          if (res.status === 200) {
            commit('SET_USER_PROPOSALS', res.data)
            resolve()
          }
        })
        .catch(err => {
          reject(err)
        })
        .finally(() => {
          commit('IS_LOADING', false)
        })
    })
  },
  UPDATE_USER_PROPOSAL ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProposalService.updateProposal({
        payload
      })
        .then(res => {
          commit('UPDATE_USER_PROPOSAL', payload)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  DELETE_USER_PROPOSAL ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProposalService.deleteProposal({
        proposalId: payload.fromBidders ? payload.proposalId : payload
      })
        .then(res => {
          if (res.status === 200) {
            commit('DELETE_USER_PROPOSAL', payload)
            resolve()
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  removeSingleProposalAtJob ({ commit }, payload) {
    commit('REMOVE_USER_PROPOSAL')
  },
  retractProposalAtBidders ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProposalService.deleteProposal({
        proposalId: payload.proposalId
      })
        .then(() => {
          commit('RETRACT_PROPOSAL_AT_BIDDERS', payload.proposalId)
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    })
    
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
