import Api from '@/services/Api'

export default {
  createBoard (params) {
    return Api().post('/api/laBoards/init', params)
  },
  postAdvice (params) {
    return Api().post('/api/la-forums/create', params)
  },
  postFollowUp (params) {
    return Api().post('/api/la-forums/create', params)
  },
  getBoard (params) {
    return Api().get('/api/laBoards/' + params.boardId)
  },
  getCompletedProblems () {
    return Api().get('/api/laBoards/a/completed')
  },
  getUnhiddenCompletedTask (params) {
    return Api().get(`/api/boards/unhidden-completed-task?page=${params.page}`)
  },
  getBoardDisputeInfo (params) {
    return Api().get('/api/disputes/board/' + params.disputeId)
  },
  addRemittedPaymentInBoard (params) {
    return Api().post('/api/boards/addPayment', params.formData)
  },
  getBoards (params) {
    return Api().get(`/api/boards/a?page=${params.page}`)
  },
  updateBoardPayment (params) {
    return Api().patch('/api/boards/updatePayment', params.formData)
  },
  editPaymentUseFunds (params) {
    return Api().patch('/api/boards/useFunds', params)
  },
  completeJob (params) {
    return Api().patch(`/api/boards/complete/${params.boardId}`, params)
  }
}
