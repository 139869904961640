export default {
  methods: {
    mobileNotif () {
      this.$router.push({ name: 'MobileNotifications' })
      this.$store.dispatch('notifications/isNotified')
    },
    showSideBar () {
      this.$refs.SideBar.showSideBar()
    },
    hiredLawyer () {
      this.socket.on('hire-lawyer', data => {
        this.$store.commit('notifications/SET_REALTIME_MOBILE', 1)
      })
    },
    disputeLawyer () {
      this.socket.on('dispute-lawyer', data => {
        this.$store.commit('notifications/SET_REALTIME_MOBILE', 1)
      })
    },
    disputeResponse () {
      this.socket.on('dispute-reply', data => {
        this.$store.commit('notifications/SET_REALTIME_MOBILE', 1)
      })
    },
    approvedDeposit () {
      this.socket.on('approve-deposit', data => {
        this.$store.commit('notifications/SET_REALTIME_MOBILE', 1)
      })
    },
    approvedPayment () {
      this.socket.on('approve-payment', data => {
        this.$store.commit('notifications/SET_REALTIME_MOBILE', 1)
      })
    },
    declinedTransaction () {
      this.socket.on('denied-transaction', data => {
        this.$store.commit('notifications/SET_REALTIME_MOBILE', 1)
      })
    },
    approvedCredits () {
      this.socket.on('approve-credits', data => {
        this.$store.commit('notifications/SET_REALTIME_MOBILE', 1)
      })
    },
    declinedCredits () {
      this.socket.on('denied-credits', data => {
        this.$store.commit('notifications/SET_REALTIME_MOBILE', 1)
      })
    },
    approvedCashout () {
      this.socket.on('approved-cashout', data => {
        this.$store.commit('notifications/SET_REALTIME_MOBILE', 1)
      })
    },
    declinedCashout () {
      this.socket.on('declined-cashout', data => {
        this.$store.commit('notifications/SET_REALTIME_MOBILE', 1)
      })
    },
    disputeComplainant () {
      this.socket.on('dispute-complainant', data => {
        this.$store.commit('notifications/SET_REALTIME_MOBILE', 1)
      })
    },
    disputeDefendant () {
      this.socket.on('dispute-defendant', data => {
        this.$store.commit('notifications/SET_REALTIME_MOBILE', 1)
      })
    },
    approveLegalAdvicePayment () {
      this.socket.on('legal-payment', data => {
        this.$store.commit('notifications/SET_REALTIME_MOBILE', 1)
      })
    },
    lawyerReply () {
      this.socket.on('lawyer-reply', data => {
        this.$store.commit('notifications/SET_REALTIME_MOBILE', 1)
      })
    },
    clientReply () {
      this.socket.on('client-reply', data => {
        this.$store.commit('notifications/SET_REALTIME_MOBILE', 1)
      })
    },
    completeJob () {
      this.socket.on('complete-job', data => {
        this.$store.commit('notifications/SET_REALTIME_MOBILE', 1)
      })
    },
  },
  mounted () {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.hiredLawyer()
      this.disputeLawyer()
      this.approvedDeposit()
      this.approvedPayment()
      this.declinedTransaction()
      this.approvedCredits()
      this.declinedCredits()
      this.approvedCashout()
      this.declinedCashout()
      this.disputeComplainant()
      this.disputeDefendant()
      this.approveLegalAdvicePayment()
      this.lawyerReply()
      this.clientReply()
      this.completeJob()
      this.disputeResponse()
    }
  },
}