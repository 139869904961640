import DisputeService from '@/services/DisputeService'

const state = {
  isLoading: false,
  disputes: [],
  selectedDispute: {
    complainant: '',
    defendant: '',
    complainantMessage: ''
  }
}

const getters = {
  userDisputes (state) {
    return state.disputes
  },
  selectedDispute (state) {
    return state.selectedDispute
  },
  isLoading (state) {
    return state.isLoading
  }
}

const mutations = {
  SET_USER_DISPUTES (state, payload) {
    state.disputes = payload
  },
  SET_SELECTED_DISPUTE (state, payload) {
    state.selectedDispute = payload
  },
  ADD_RESPONSE_TO_DISPUTE (state, payload) {
    state.selectedDispute.defendantMessage = payload
  },
  CANCEL_SELECTED_DISPUTE (state, payload) {
    for (var i = 0; i < state.disputes.length; i++) {
      if (state.disputes[i]._id === payload) {
        state.disputes.splice(i, 1)
      }
    }
    // state.disputes.splice(state.disputes.findIndex(dispute => dispute._id === payload), 1)
  },
  DELETE_SELECTED_DISPUTE (state, payload) {
    let index = state.disputes.indexOf(payload)
    state.disputes.splice(index, 1)
  },
  IS_LOADING (state, payload) {
    state.isLoading = payload
  }
}

const actions = {
  ADD_RESPONSE ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      DisputeService.addResponse({
        disputeId: payload.disputeId,
        defendantMessage: payload.message,
        complainant: payload.complainant
      })
        .then(res => {
          commit('ADD_RESPONSE_TO_DISPUTE', res.data)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  GET_USER_DISPUTES ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('IS_LOADING', true)
      DisputeService.getDisputes({ page: payload.page })
        .then(res => {
          commit('SET_USER_DISPUTES', res.data.docs)
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
        .finally(() => {
          commit('IS_LOADING', false)
        })
    })
  },
  GET_SELECTED_DISPUTE ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('IS_LOADING', true)
      DisputeService.getDispute({
        disputeId: payload
      })
        .then(res => {
          commit('SET_SELECTED_DISPUTE', res.data[0])
          resolve(res.data[0])
        })
        .catch(err => {
          reject(err)
        })
        .finally(() => {
          commit('IS_LOADING', false)
        })
    })
  },
  CANCEL_SELECTED_DISPUTE ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      DisputeService.cancelDispute({
        ...payload
      })
        .then(res => {
          commit('CANCEL_SELECTED_DISPUTE', payload.disputeId)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteDispute ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      DisputeService.deleteDispute({ disputeId: payload._id })
        .then(res => {
          commit('DELETE_SELECTED_DISPUTE', payload)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
