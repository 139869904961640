import TransactionService from '@/services/TransactionService'
import VueCookie from 'vue-cookie'

const state = {
  transactions: [],
  wallet: 0,
  isFetching: false
}

const getters = {
  transactions (state) {
    return state.transactions
  },
  wallet (state) {
    return state.wallet
  },
  isFetching (state) {
    return state.isFetching
  }
}

const mutations = {
  ADD_TRANSACTIONS (state, payload) {
    payload.createdAt = new Date()
    state.transactions.unshift(payload)
    if (payload.type === 'Cashout') {
      state.wallet = state.wallet - payload.amount
    }
  },
  ADD_CREDITS_USEFUNDS (state, payload) {
    state.transactions.unshift(payload)
    state.wallet = state.wallet - payload.amount
  },
  ADD_IMAGE_TO_TRANSACTION (state, payload) {
    state.transactions.find(x => x._id === payload._id).imageUrl = payload.imageUrl
    state.transactions.find(x => x._id === payload._id).status = 'Verifying Payment'
  },
  SET_TRANSACTIONS (state, payload) {
    state.transactions = payload.transactions.docs
    state.wallet = payload.wallet
  },
  SET_TRANSACTIONS_BY_SELECTIVE (state, payload) {
    state.transactions = payload
  },
  UPDATE_CASHOUT (state, payload) {
    state.transactions.find(transaction => transaction._id === payload._id).accountNumber = payload.accountNumber
    state.transactions.find(transaction => transaction._id === payload._id).accountName = payload.accountName
    state.transactions.find(transaction => transaction._id === payload._id).status = payload.status
  },
  DELETE_PENDINGS (state, payload) {
    for (var i = 0; i < state.transactions.length; i++) {
      if (state.transactions[i]._id === payload.transactionId) {
        state.transactions.splice(i, 1)
        if (payload.type === 'Cashout') {
          state.wallet += payload.amount
        }
      }
    }
  },
  SUBTRACT_FUNDS (state, payload) {
    state.wallet = state.wallet - payload
  },
  IS_FETCHING (state, payload) {
    state.isFetching = payload
  }
}

const actions = {
  ADD_FUNDS ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      if (payload.hasRemitted) {
        TransactionService.addFunds({
          payload: payload.formData
        })
          .then(res => {
            commit('ADD_TRANSACTIONS', res.data.transaction)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      } else {
        TransactionService.addNotYetRemittedFunds({
          payload
        })
          .then(res => {
            commit('ADD_TRANSACTIONS', res.data.transaction)
            resolve()
          })
      }
    })
  },
  ADD_TRANSACTIONS ({ commit }, payload) {
    payload.createdAt = new Date()
    commit('ADD_TRANSACTIONS', payload)
  },
  ADD_IMAGE_TO_TRANSACTION ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      TransactionService.uploadReceipt({
        payload
      })
        .then(res => {
          let load = {
            _id: payload.get('id'),
            index: payload.get('index'),
            imageUrl: res.data.imageUrl
          }
          commit('ADD_IMAGE_TO_TRANSACTION', load)
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  GET_TRANSACTIONS ({ commit }, payload) {
    commit('IS_FETCHING', true)
    return new Promise((resolve, reject) => {
      TransactionService.getTransactions({
        userId: VueCookie.get('userId'),
        page: payload.page
      })
        .then(res => {
          commit('SET_TRANSACTIONS', res.data)
          resolve(res.data.transactions)
          commit('IS_FETCHING', false)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  GET_SELECTIVE_TRANSACTIONS ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      TransactionService.getSelectiveTransactions({
        type: payload
      })
        .then(res => {
          commit('SET_TRANSACTIONS_BY_SELECTIVE', res.data.selectiveTransactions)
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  UPDATE_CASHOUT ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      TransactionService.updateCashout(payload)
        .then(res => {
          commit('UPDATE_CASHOUT', res.data)
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  DELETE_PENDINGS ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      TransactionService.deletePendings({
        transactionId: payload.transactionId
      })
        .then(res => {
          resolve(res)
          commit('DELETE_PENDINGS', payload)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  BUY_CREDITS ({ commit }, payload) {
    payload.createdAt = new Date()
    return new Promise((resolve, reject) => {
      TransactionService.buyCredits({
        transaction: payload
      })
        .then(res => {
          resolve(res)
          commit('ADD_TRANSACTIONS', payload)
        })
        .catch(err => {
          resolve(err)
        })
    })
  },
  BUY_CREDITS_WITH_FUNDS ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      TransactionService.buyCreditsWithFunds({
        amount: payload.amount,
        creditPricingId: payload.creditPricingId
      })
        .then(res => {
          commit('ADD_CREDITS_USEFUNDS', payload)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  BUY_CREDITS_WITH_REMITTANCE ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      TransactionService.buyCreditsWithRemittance({
        payload
      })
        .then(res => {
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  CREATE_CASHOUT ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      TransactionService.createCashout({
        details: payload
      })
        .then(res => {
          commit('ADD_TRANSACTIONS', res.data)
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  DELETE_PENDING_CASHOUT ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      TransactionService.deletePendingCashout({
        id: payload.id
      })
        .then(res => {
          resolve(res)
          if (res.status === 200) {
            let load = {
              index: payload.index,
              amount: payload.amount,
              type: payload.type
            }

            commit('DELETE_PENDINGS', load)
          }
        })
        .catch(err => {
          resolve(err)
        })
    })
  },
  USE_FUNDS ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      TransactionService.useFunds({
        details: payload
      })
        .then(res => {
          commit('SUBTRACT_FUNDS', payload.amount)
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
