import LegalAdviceServices from '@/services/LegalAdviceServices'
import VueCookie from 'vue-cookie'

const state = {
  legalAdvice: {
    conversations: []
  }
}

const getters = {
  legalAdvice (state) {
    return state.legalAdvice
  },
  clientId (state) {
    return state.legalAdvice.client._id
  },
  lawyerId (state) {
    return state.legalAdvice.lawyer._id
  },
  isLawyerReply (state) {
    return state.legalAdvice.lawyerReply
  },
  conversations (state) {
    return state.legalAdvice.conversations
  },
  conversationImages (state) {
    let images = []

    for (let i = 0; i < state.legalAdvice.conversations.length; i++) {
      if ( state.legalAdvice.conversations[i].isFile == true) images.push(state.legalAdvice.conversations[i].message)
    }

    return images
  },
  documents (state) {
    return state.legalAdvice.documents
  },
  followUps (state) {
    return state.legalAdvice.followUps
  }
}

const mutations = {
  SET_MESSAGE_REALTIME (state, payload) {
    state.legalAdvice.conversations.push(payload)
  },
  SET_LEGAL_ADVICE (state, payload) {
    state.legalAdvice = payload
  },
  SET_LEGAL_DOCUMENT (state, payload) {
    state.legalAdvice.documents.unshift(payload)
  },
  SET_MESSAGE (state, payload) {
    state.legalAdvice.conversations.push({
      _id: VueCookie.get('userId'),
      message: payload.message,
      createdAt: new Date(),
      isAdvice: state.legalAdvice.lawyerReply
    })

    if (payload.lawyerReply) {
      --state.legalAdvice.followUps
    }
    state.legalAdvice.lawyerReply = payload.lawyerReply
  },
  SET_LEGAL_ADVICE_LIMIT (state, payload) {
    state.legalAdvice.limitDate = payload
  },
  ADD_PAYMENT (state, payload) {
    state.legalAdvice.status = 'Verifying Payment'
    state.legalAdvice.payment = payload.transaction
  },
  ADD_PAYMENT_USE_FUNDS (state, payload) {
    state.legalAdvice.payment = payload
  },
  UPDATE_PAYMENT (state, payload) {
    state.legalAdvice.payment.status = 'Verifying Payment'

    if (payload) {
      state.legalAdvice.payment.cloudinaryPublicId = payload
    }
  },
  UPDATE_PAYMENT_STATUS_APPROVED (state) {
    state.legalAdvice.status = 'Payment Verified'
  },
  CANCEL_TASK (state) {
    state.legalAdvice.status = 'Cancelled'
  },
  COMPLETE_LEGAL_ADVICE (state) {
    state.legalAdvice.isFinish = true
    state.legalAdvice.status = 'Completed'
  }
}

const actions = {
  getLegalAdviceBoard ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      LegalAdviceServices.getLegalAdviceBoard(payload)
        .then(res => {
          commit('SET_LEGAL_ADVICE', res.data)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  sendMessage ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      LegalAdviceServices.sendMessage(payload)
        .then(res => {
        //  commit('SET_MESSAGE', payload)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  sendDocument ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      LegalAdviceServices.sendFile(payload)
        .then(res => {
          //commit('SET_LEGAL_DOCUMENT', res.data)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  realTimeDocument ({ commit }, payload) {
    commit('SET_LEGAL_DOCUMENT', payload)
  },
  realTimeMessage ({ commit }, payload) {
    commit('SET_MESSAGE_REALTIME', payload)
  },
  updatePaymentStatus ({ commit }) {
    commit('UPDATE_PAYMENT_STATUS_APPROVED')
  },
  completeLegalAdvice ({ commit }) {
    commit('COMPLETE_LEGAL_ADVICE')
  },
  cancelTask ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      LegalAdviceServices.cancelRefundTask({
        adviceId: payload.adviceId,
        amount: payload.amount
      })
        .then(res => {
          commit('CANCEL_TASK')
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
  // realTimeMessage ({ commit }) {
  //   io('http://localhost:3000').on('reply', (data) => {
  //     commit('SET_LEGAL_ADVICE', data)
  //   })
  // }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
