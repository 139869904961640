<template>
  <div>
    <v-sheet v-if="!isFetching">
      <div v-if="!isFetching && notifications.length > 0" class="bgApp" flat>
        <v-card-actions class="px-3 pt-3">
          <span class="font-weight-bold subheading">Notifications</span>
          <v-spacer />
          <v-icon color="error" @click="clearNotifications">delete</v-icon>
        </v-card-actions>
        <v-row v-for="(notif, index) in notifications" :key="index" justify="center" class="bgApp pb-2" no-gutters>
          <v-col cols="1" class="pl-2 pt-1">
              <v-avatar v-if="notif.notifType === 'Hired Lawyer' || notif.notifType === 'Disputed Lawyer' || notif.notifType === 'Board Reply' || notif.notifType === 'Advice Completed'" size="32">
                <img v-if="!isLawyer" :src="notif.client.imageUrl || 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'">
                <img v-if="!notif.problem.isAnonymous && isLawyer" :src="notif.client.imageUrl || 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'">
                <img v-if="notif.problem.isAnonymous && isLawyer" src='https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'>
              </v-avatar>
              <v-avatar v-if="notif.notifType === 'Legal Advice Proposal'" size="32" color="primary">
                <v-icon class="white--text">subject</v-icon>
              </v-avatar>
              <v-avatar v-if="notif.notifType === 'Approved Deposit' || notif.notifType === 'Add Credits' || notif.notifType === 'Approved Cashout' || notif.notifType === 'Legal Advice Paid' || notif.notifType === 'Override Task'" size="32" color="success">
                <v-icon class="white--text">check</v-icon>
              </v-avatar>
              <v-avatar v-if="notif.notifType === 'Denied' || notif.notifType  === 'Denied Credits' || notif.notifType  === 'Denied Cashout'" size="32" color="error">
                <v-icon class="white--text">clear</v-icon>
              </v-avatar>
              <v-avatar v-if="notif.notifType === 'Dispute Award' && notif.awardedTo === 'Complainant' && !isLawyer" size="32" color="yellow darken-1">
                <v-icon class="white--text">emoji_events</v-icon>
              </v-avatar>
              <v-avatar v-if="notif.notifType === 'Dispute Award' && notif.awardedTo === 'Complainant' && isLawyer" size="32" color="error">
                <v-icon class="white--text">emoji_events</v-icon>
              </v-avatar>
              <v-avatar v-if="notif.notifType === 'Dispute Award' && notif.awardedTo === 'Defendant' && isLawyer" size="32" color="yellow darken-1">
                <v-icon class="white--text">emoji_events</v-icon>
              </v-avatar>
              <v-avatar v-if="notif.notifType === 'Dispute Award' && notif.awardedTo === 'Defendant' && !isLawyer" size="32" color="error">
                <v-icon class="white--text">emoji_events</v-icon>
              </v-avatar>
              <v-avatar v-if="notif.notifType === 'Dispute Response'" size="32" color="primary">
                <v-icon class="white--text">gavel</v-icon>
              </v-avatar>
            </v-col>
            <v-col cols="10" class="pl-5" @click="redirectNotification(notif.notifType, notif.legalAdviceId, notif.disputeId, notif.problem ? notif.problem._id : '')">
              <span v-if="notif.notifType === 'Legal Advice Proposal'" class="black--text caption">You have {{ notif.proposalCount }} proposal{{ notif.proposalCount > 1 ? 's' : '' }} on your <span class="primary--text">Legal Advice</span></span>
              <span v-if="notif.notifType === 'Hired Lawyer' && !notif.problem.isAnonymous" class="caption">You have been hired by <span class="font-weight-bold">{{ notif.client.fullName | slice }}</span></span>
              <span v-if="notif.notifType === 'Hired Lawyer' && notif.problem.isAnonymous" class="caption">You have been hired by an <span class="font-weight-bold">Anonymous User.</span></span>
              <span v-if="notif.notifType === 'Legal Advice Paid' && !isLawyer" class="caption">Your {{ notif.transaction.amount | phCurrency}} <span class="font-weight-bold">{{ notif.transaction.type }}</span> has been approved.</span>
              <span v-if="notif.notifType === 'Legal Advice Paid' && isLawyer && !notif.problem.isAnonymous" class="caption"><span class="font-weight-bold">{{ notif.client.fullName | slice }}</span> has paid {{ notif.transaction.amount | phCurrency}} as payment for your <span class="font-weight-bold">Legal Advice</span>. This will be forwarded to your wallet as soon as the task is completed. You may now answer his/her question.</span>
              <span v-if="notif.notifType === 'Legal Advice Paid' && isLawyer && notif.problem.isAnonymous" class="overline"><span class="font-weight-bold">The Anonymous User</span> has paid {{ notif.transaction.amount | phCurrency}} as payment for your <span class="font-weight-bold">Legal Advice</span>. This will be forwarded to your wallet as soon as the task is completed. You may now answer his/her question. </span>
              <span v-if="notif.notifType === 'Board Reply' && isLawyer && !notif.problem.isAnonymous" class="caption"><span class="font-weight-bold">{{ notif.client.fullName | slice }}</span> has replied and is waiting for your response.</span>
              <span v-if="notif.notifType === 'Board Reply' && isLawyer && notif.problem.isAnonymous" class="caption"><span class="font-weight-bold">Anonymous User</span> has replied and is waiting for your response.</span>
              <span v-if="notif.notifType === 'Board Reply' && !isLawyer" class="caption"><span class="font-weight-bold">Atty. {{ notif.client.fullName | slice }}</span> has replied. You can now use your follow-ups and ask questions.</span>
              <span v-if="notif.notifType === 'Advice Completed' && !notif.problem.isAnonymous" class="caption"><span class="font-weight-bold">{{ notif.client.fullName | slice }}</span> has rated and completed the job for <span class="font-weight-bold">{{ notif.transaction.type }}</span>. {{ notif.transaction.amount | amountReceived }} has added to your wallet.</span>
              <span v-if="notif.notifType === 'Advice Completed' && notif.problem.isAnonymous" class="caption"><span class="font-weight-bold">Anonymous User</span> has rated and completed the job for <span class="font-weight-bold">{{ notif.transaction.type }}</span>. {{ notif.transaction.amount | amountReceived }} has added to your wallet.</span>
              <span v-if="notif.notifType === 'Approved Deposit'" class="caption">Your {{ notif.transaction.amount | phCurrency}} <span class="font-weight-bold">{{ notif.transaction.type }}</span> has been approved.</span>
              <span v-if="notif.notifType === 'Denied'" class="caption">Your {{ notif.transaction.amount | phCurrency}} <span class="font-weight-bold">{{ notif.transaction.type }}</span> has been denied.</span>
              <span v-if="notif.notifType === 'Add Credits'" class="caption">Your {{ notif.transaction.amount | phCurrency}} <span class="font-weight-bold">{{ notif.transaction.type }}</span> has been approved.</span>
              <span v-if="notif.notifType === 'Denied Credits'" class="caption">Your {{ notif.transaction.amount | phCurrency}} <span class="font-weight-bold">{{ notif.transaction.type }}</span> has been denied.</span>
              <span v-if="notif.notifType === 'Approved Cashout'" class="caption">Your {{ notif.transaction.amount | phCurrency }} <span class="font-weight-bold">Cashout</span> has been approved.</span>
              <span v-if="notif.notifType === 'Denied Cashout'" class="caption">Your {{ notif.transaction.amount | phCurrency }} <span class="font-weight-bold">Cashout</span> has been declined.</span>
              <span v-if="notif.notifType === 'Disputed Lawyer' && !notif.problem.isAnonymous" class="caption"><span class="font-weight-bold">{{ notif.client.fullName | slice }}</span> has filed a dispute.</span>
              <span v-if="notif.notifType === 'Disputed Lawyer' && notif.problem.isAnonymous" class="caption"><span class="font-weight-bold">Anonymous </span> Anonymous has filed a dispute.</span>
              <span v-if="notif.notifType === 'Dispute Response'" class="caption">The lawyer has responded to your dispute.</span>
              <span v-if="notif.notifType === 'Dispute Award' && notif.awardedTo === 'Complainant' && !isLawyer" class="caption">Our team awarded the <span class="font-weight-bold">Dispute</span> to you.</span>
              <span v-if="notif.notifType === 'Dispute Award' && notif.awardedTo === 'Complainant' && isLawyer" class="caption">Our team awarded the <span class="font-weight-bold">Dispute</span> to the Client.</span>
              <span v-if="notif.notifType === 'Dispute Award' && notif.awardedTo === 'Defendant' && isLawyer" class="caption">Our team awarded the <span class="font-weight-bold">Dispute</span> to you.</span>
              <span v-if="notif.notifType === 'Dispute Award' && notif.awardedTo === 'Defendant' && !isLawyer" class="caption">Our team awarded the <span class="font-weight-bold">Dispute</span> to the Lawyer.</span>
              <v-list-item-subtitle class="caption grey--text">{{ notif.createdAt | momentFromNow }}</v-list-item-subtitle>
            </v-col>
            <v-col cols="1">
            <v-icon
              color="error"
              class="pr-2"
              @click="deleteNotif(notif._id, index)"
              small
            >mdi-close</v-icon>
          </v-col>
        </v-row>
      </div>
      <v-sheet v-else style="height: 86vh;" class="pb-5 bgApp">
        <v-layout row wrap justify-content-center fill-height align-center>
          <v-flex xs12 class="text-center">
            <img class="mx-2 pb-3" width="140" src="https://res.cloudinary.com/starupgeek-law/image/upload/v1591672775/assets/fr5wbkbcgogespz3umtl.png"/>
            <p class="title font-weight-medium grey--text">
              All caught up!
            </p>
          </v-flex>
        </v-layout>
      </v-sheet>
    </v-sheet>
    <v-sheet v-else style="height: 90vh;" class="bgApp">
      <v-layout row wrap justify-center fill-height align-center>
        <v-progress-circular
          :size="100"
          :width="5"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-layout>
    </v-sheet>
  </div>
</template>

<script>
import NotificationService from '@/services/NotificationService'
import accountingMixins from '@/mixins/accountingMixins'
import fromNowMixin from '@/mixins/momentFromNowMixin'

export default {
  name: 'MobileNotifications',
  mixins: [
    accountingMixins,
    fromNowMixin
  ],
  data () {
    return {
      transactionTypes: [
        'Approved Deposit',
        'Denied',
        'Advice Completed'
      ]
    }
  },
  methods: {
    redirectNotification (notifType, adviceId, disputeId, problemId) {
      if (notifType === 'Hired Lawyer' || notifType === 'Legal Advice Paid' || notifType === 'Board Reply') {
        this.$router.push({ name: 'Board', params: { boardId: adviceId } })
      }
      else if (notifType === 'Legal Advice Proposal') {
        this.$router.push({ name: 'OpenJob', params: { problemId: problemId } })
      }
      else if (notifType === 'Disputed Lawyer' || notifType === 'Dispute Award' || notifType === 'Dispute Response') {
        this.$router.push({ name: 'Dispute-ViewOne', params: { disputeId: disputeId } })
      }
      else if (notifType === 'Advice Completed') {
        this.$router.push({ name: 'Overview' })
      }
      else if (notifType === 'Override Task' && this.isLawyer) {
        this.$router.push({ name: 'Transactions' })
      }
      else if (notifType === 'Override Task' && !this.isLawyer) {
        this.$router.push({ name: 'Board', params: { boardId: adviceId } })
      }
      else if (notifType.indexOf(this.transactionTypes)) {
        this.$router.push({ name: 'Transactions' })
      }
    },
    clearNotifications () {
      this.$store.dispatch('notifications/clearNotifications')
    },
    deleteNotif (notifId, index) {
      this.closed = false
      NotificationService.deleteNotif({ notifId })
        .then(() => {
          this.notifications.splice(index, 1)
        })
        .catch(() => {
          this.$swal({
            type: 'error',
            text: 'Something went wrong with the server.',
            confirmButtonColor: this.$vuetify.theme.primary,
            onOpen: () => { document.activeElement.blur() }
          })
        })
    }
  },
  computed: {
    notifications () {
      return this.$store.getters['notifications/notifications']
    },
    isLawyer () {
      return this.$store.getters['user/user'].user.isLawyer
    },
    isFetching () {
      return this.$store.getters['notifications/isFetching']
    }
  },
  created () {
    this.$store.dispatch('notifications/getNotifications')
  },
  filters: {
    slice: function (value) {
      if (!value) return ''
      if (/\s/.test(value)) {
        value = value.toString()
        return value.substr(0, value.indexOf(' '))
      } else {
        return value
      }
    }
  },
}
</script>

<style scoped>

</style>