<template>
  <v-flex xs12 pa-0>
    <v-sheet class="pa-2 primary dropShadow">
      <v-flex v-if="!$vuetify.breakpoint.mdAndDown" xs8 class="px-2 mx-auto">
        <v-layout class="mx-auto" align-center>
          <p class="mb-0 white--text">Please verify your account now to access all the features.</p>
          <v-spacer></v-spacer>
          <span class=" white--text">
            Email not recieved?
            <v-btn
              small
              depressed
              @click="resendVerification()"
            >Resend Verification</v-btn>
          </span>
        </v-layout>
      </v-flex>
      <v-layout v-else row wrap align-center>
        <v-flex xs10>
          <p class="mb-0 caption white--text">
            Please verify your account now to access all the features.
            <span
              class="font-weight-bold"
              @click="resendVerification()"
            >Email not recieved? Resend verification.</span>
          </p>
        </v-flex>
        <v-flex xs2>
          <v-btn icon small class="white--text" @click="$store.dispatch('control/changeShowPleaseVerify')"><v-icon>clear</v-icon></v-btn>
        </v-flex>
      </v-layout>
    </v-sheet>
  </v-flex>
</template>

<script>
import VerificationService from '@/services/VerificationService'
import moment from 'moment'

export default {
  name: 'PleaseVerifiy',
  methods: {
    resendVerification () {
      VerificationService.resendEmailVerification(this.$store.getters['user/user'].user._id)
        .then(res => {
          this.$store.dispatch('control/changeShowPleaseVerify')
          this.$swal({
            type: 'success',
            text: 'Successfully sent the email verification.',
            confirmButtonColor: this.$vuetify.theme.primary,
            onOpen: () => { document.activeElement.blur() }
          })
        })
        .catch(err => {
          this.$swal({
            type: 'error',
            text: 'Something went wrong with the server.',
            confirmButtonColor: this.$vuetify.theme.primary,
            onOpen: () => { document.activeElement.blur() }
          })
        })
    },
    checkNextResend () {
      return moment(this.$store.getters['user/user'].user.nextResendTime).isSameOrBefore(new Date())
    }
  }
}
</script>

<style scoped>
  .cool-link:hover {
    color: brown;
  }
</style>
