import Api from '@/services/Api'

export default {
  getNotifications () {
    return Api().get('/api/userNotifications/a')
  },
  isNotified () {
    return Api().patch('/api/userNotifications/notified')
  },
  clearAllNotifs () {
    return Api().delete('/api/userNotifications/clear')
  },
  deleteNotif (params) {
    return Api().delete(`/api/userNotifications/delete/${params.notifId}`)
  }
}