import BoardService from '@/services/BoardService'

const state = {
  board: {
    payment: [{
      status: 'Pending'
    }]
  },
  client: {},
  problem: {},
  forums: {}
}

const getters = {
}

const mutations = {
  SET_TO_PENDING (state) {
    state.board.status = ''
  },
  ADD_BOARD_PAYMENT (state, payload) {
    state.board.payment[0] = payload
  },
  CHANGE_LAWYER_REPLY (state, payload) {
    state.board.lawyerReply = payload
  },
  UPDATE_BOARD_PAYMENT (state, payload) {
    state.board.payment[0].status = 'Verifying Payment'

    if (payload.cloudinaryPublicId) {
      state.board.payment[0].cloudinaryPublicId = payload.cloudinaryId
    }
  },
  INSERT_DISPUTE_INFO (state, payload) {
    state.board.dispute = payload[0]
  }
}

const actions = {
  GET_BOARD_DISPUTE ({ commit }, payload) {
    commit('SET_BOARDLOADING', true)
    return new Promise((resolve, reject) => {
      BoardService.getBoardDisputeInfo({
        disputeId: payload
      })
        .then(res => {
          commit('INSERT_DISPUTE_INFO', res.data)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  ADD_PAYMENT_IN_BOARD ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      BoardService.addRemittedPaymentInBoard({ formData: payload.formData })
        .then(res => {
          if (payload.service === 'Legal Advice') {
            commit('legalAdvice/ADD_PAYMENT', res.data, { root: true })
          }
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  UPDATE_PAYMENT ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      BoardService.updateBoardPayment({ formData: payload.formData })
        .then(res => {
          if (payload.service === 'Legal Advice') {
            commit('legalAdvice/UPDATE_PAYMENT', res.data.cloudinaryPublicId, { root: true })
          }
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  UPDATE_PAYMENT_USE_FUNDS ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      BoardService.editPaymentUseFunds(payload)
        .then(res => {
          if (payload.service === 'Legal Advice') {
            commit('legalAdvice/SET_LEGAL_ADVICE_LIMIT', res.data.dateLimit.limitDate, { root: true })
            commit('legalAdvice/ADD_PAYMENT_USE_FUNDS', res.data.transaction, { root: true })
          }
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  SET_TO_PENDING ({ commit }, payload) {
    commit('SET_TO_PENDING')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
