import Api from '@/services/Api'

export default {
  postLAProposals (params) {
    return Api().post('/api/proposals/legal-advice', params)
  },
  getLAProposals (params) {
    return Api().get('/api/proposals/a/legal-advice/' + params.problemId)
  },
  getMyProposals () {
    return Api().get('/api/proposals/a/')
  },
  getMyProposal (params) {
    return Api().get(`/api/proposals/single/${params.problemId}`)
  },
  getMyArchivedProposals () {
    return Api().get('/api/proposals/a/archived')
  },
  updateProposal (params) {
    return Api().patch('/api/proposals/update/' + params.payload._id, params.payload)
  },
  deleteProposal (params) {
    return Api().delete('/api/proposals/delete/' + params.proposalId)
  }
}
