import Api from '@/services/Api'

export default {
  getDisputes (params) {
    return Api().get(`/api/disputes/a?page=${params.page}`)
  },
  getDispute (params) {
    return Api().get('/api/disputes/' + params.disputeId)
  },
  createDispute (params) {
    return Api().post('/api/disputes/', params)
  },
  addResponse (params) {
    return Api().patch(`/api/disputes/${params.disputeId}/respond`, params)
  },
  cancelDispute (params) {
    return Api().patch(`/api/disputes/cancel/${params.disputeId}`, params)
  },
  deleteDispute (params) {
    return Api().patch(`/api/disputes/hide-dispute/${params.disputeId}`)
  }
}
