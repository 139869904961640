<template>
  <div>
    <SideToolBar ref="SideBar"/>
    <v-app-bar
      :scroll-off-screen="$vuetify.breakpoint.mdAndDown"
      :scroll-threshold="60"
      color="white"
      height="60"
      class="px-0"
      style="box-shadow: 0 1px 3px 0 hsla(0, 0%, 0%, 0.1);"
      app
      fixed
    >
      <v-layout align-center justify-center row wrap>
        <v-flex xs4 sm3 md2 lg1>
          <router-link :to="user.isLawyer ? '/jobs' : '/post-problem'">
            <img
              class="ml-1 pt-2"
              width="100%"
              src="@/assets/LawyerKo_Full_Black.png"
            />
          </router-link>
        </v-flex>
        <v-flex v-if="!$vuetify.breakpoint.mdAndDown" xs4 md6>
          <div v-if="user.isLawyer" class="pl-3">
            <v-btn
              to="/jobs"
              slot="activator"
              class="font-weight-bold mx-1"
              text
              small
            >Find Tasks</v-btn>
            <v-btn
              to="/tasks"
              slot="activator"
              class="font-weight-bold mx-1"
              text
              small
            >My Tasks</v-btn>
            <!-- <v-btn
              to="/transactions"
              class="font-weight-bold mx-1"
              text
              small
            >My Wallet</v-btn> -->
          </div>
          <div v-else class="pl-3">
            <v-btn
              color="primary"
              to="/post-problem"
              class="mx-1"
              depressed
              text
            >Post Task</v-btn>
            <v-btn
              to="/tasks"
              class="font-weight-bold mx-1"
              text
            >Tasks</v-btn>
            <!-- <v-btn
              to="/transactions"
              class="font-weight-bold mx-1"
              text
            >My Wallet</v-btn> -->
          </div>
        </v-flex>
        <v-flex v-if="!$vuetify.breakpoint.mdAndDown" xs2 class="text-right">
          <v-chip
            :to="user.isLawyer ? '/overview' : '/settings/profile'"
            color="transparent"
            style="cursor: pointer;"
            class="mr-3"
          >
            <v-avatar left>
              <v-img
                :src="user.imageUrl || 'https://s3.amazonaws.com/baptist-health-sparks/wp-content/uploads/2018/10/29183342/default-profile.png'"
                alt="avatar"
              ></v-img>
            </v-avatar>
            <span>{{ user.fullName | slice }}</span>
          </v-chip>
          <Notifications :socket="socket" /> 
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-avatar v-on="on" size="40" color="bgGrey" class="ml-3" style="cursor: pointer;">
                <v-icon color="black">mdi-chevron-down</v-icon>
              </v-avatar>
            </template>
            <v-list>
              <v-list-item v-if="user.isLawyer" to="/overview">
                <v-icon>remove_red_eye</v-icon>
                <v-list-item-title class="pl-2">View Profile</v-list-item-title>
              </v-list-item>
              <v-list-item to="/settings/profile">
                <v-icon>mdi-cog</v-icon>
                <v-list-item-title class="pl-2">Settings</v-list-item-title>
              </v-list-item>
              <v-list-item @click="logOut()" >
                <div v-if="loggingOut">
                  <v-btn text :loading="loggingOut">Logging Out</v-btn>
                </div>
                <v-icon v-if="!loggingOut">mdi-logout-variant</v-icon>
                <v-list-item-title v-if="!loggingOut" class="pl-2">Logout</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-flex>
        <v-flex xs8 v-if="$vuetify.breakpoint.mdAndDown" class="text-right pa-0" style="margin-right: -10px;">
          <v-badge overlap color="error" v-model="$store.getters['notifications/notif']" :content="$store.getters['notifications/notifCount']" >
            <v-avatar size="40" color="bgGrey" class="mr-1" style="cursor: pointer;" @click="mobileNotif">
              <v-icon color="black">mdi-bell</v-icon>
            </v-avatar>
          </v-badge>
          <v-avatar @click="showSideBar" color="bgGrey" size="40" class="mr-5 ml-2">
            <v-icon>mdi-menu</v-icon>
          </v-avatar>
        </v-flex>
      </v-layout>
    </v-app-bar>
  </div>
</template>

<script>
import io from 'socket.io-client'
import SideToolBar from './SideToolBar'
import Notifications from './Notifications'
import MobileNotifications from './MobileNotifications'
import MobileNotifMixins from '@/mixins/mobileNotificationMixins'

export default {
  components: {
    SideToolBar,
    Notifications,
    MobileNotifications
  },
  mixins: [ MobileNotifMixins ],
  data () {
    return {
      socket: io(process.env.VUE_APP_SERVER),
      loggingOut: false
    }
  },
  methods: {
    regOptions (index) {
      if (index === 0) {
        this.$store.commit('header/change', 0)
        this.$router.push('/register')
      } else {
        this.$store.commit('header/change', 1)
        this.$router.push('/register')
      }
    },
    logOut () {
      this.$store.dispatch('auth/DELETE_TOKEN')
        .then(res => {
          this.socket.emit('logout-lawyer', { email: this.$store.getters['user/user'].user.email })
          this.$router.push('/')
          this.$store.dispatch('user/DELETE_USER_STATE')
        })
    }
  },
  computed: {
    loggedIn () {
      return this.$store.getters['auth/loggedIn']
    },
    user () {
      return this.$store.getters['user/user'].user
    }
  },
  created () {
    this.$store.dispatch('notifications/getNotifCount')
    if (this.$vuetify.breakpoint.mdAndDown) {
      this.socket.emit('login-users', { email: this.$store.getters['user/user'].user.email })
    }
  },
  filters: {
    slice: function (value) {
      if (!value) return ''
      if (/\s/.test(value)) {
        value = value.toString()
        return value.substr(0, value.indexOf(' '))
      } else {
        return value
      }
    }
  }
}
</script>

<style scoped>
  .tile {
    margin: 5px;
    border-radius: 4px;
  }
  .tile:hover {
    background: green;
  }
  .tile:active {
    background: yellow;
  }
  .scroll {
    overflow-y: auto;
    padding-top: 2%
  }
  .greyBack {
    background-color: grey;
  }
</style>
