import Api from '@/services/Api'

export default {
  useFunds (params) {
    return Api().post('/api/accountings/useFunds', params)
  },
  uploadReceipt (params) {
    return Api().post('/api/accountings/addRemittanceImage', params.payload)
  },
  addFunds (params) {
    return Api().post('/api/accountings/addRemittedFunds', params.payload)
  },
  addNotYetRemittedFunds (params) {
    return Api().post('api/accountings/addNotYetRemittedFunds', params.payload)
  },
  buyCredits (params) {
    return Api().post('/api/accountings/buy/credits', params)
  },
  buyCreditsWithFunds (params) {
    return Api().post('/api/accountings/buy/credits/use-funds', params)
  },
  buyCreditsWithRemittance (params) {
    return Api().post('/api/accountings/buy/credits/remitted', params.payload)
  },
  createCashout (params) {
    return Api().post('/api/accountings/cashout', params)
  },
  getTransactions (params) {
    return Api().get(`/api/accountings/transactions?page=${params.page}`, params)
  },
  getSelectiveTransactions (params) {
    return Api().get(`/api/accountings/transactions/selective?type=${params.type}`)
  },
  getCashout (params) {
    return Api().get('/api/accountings/cashout/' + params.cashoutId)
  },
  updateCashout (params) {
    return Api().put('/api/accountings/cashout/update/' + params.cashoutId, params)
  },
  deletePendings (params) {
    return Api().delete('/api/accountings/pendings/' + params.transactionId)
  },
  deletePendingCashout (params) {
    return Api().delete('/api/accountings/cashout/' + params.id)
  }
}
