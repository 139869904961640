<template>
  <v-navigation-drawer
    v-if="$vuetify.breakpoint.mdAndDown"
    v-model="drawer"
    clipped
    disable-resize-watcher
    app
  >
    <v-list class="pa-1 primary white--text">
      <v-list-item :to="user.isLawyer ? '/overview' : ''">
        <v-list-item-avatar>
          <img :src="user.imageUrl">
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="white--text">{{ user.fullName }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list v-if="user.isLawyer" class="pt-0">
      <v-list-item to="/jobs" color="primary">
        <v-list-item-action>
          <v-icon>list_alt</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Find Tasks</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/tasks" color="primary">
        <v-list-item-action>
          <v-icon>work</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>My Tasks</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- <v-list-item to="/transactions" color="primary">
        <v-list-item-action>
          <v-icon>account_balance_wallet</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>My Wallet</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <v-list-group>
        
        <template v-slot:activator>
          <v-icon class="mr-4">mdi-cog</v-icon>
          <v-list-item>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item>
        </template>
          <div v-for="item in settings" :key="item.title">
            <v-list-item :to="item.to" color="primary">
              <v-list-item-action />
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
      </v-list-group>
      <v-list-item  @click="logOut" class="error">
        <v-list-item-action>
          <v-icon class="white--text">exit_to_app</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="white--text">Sign Out</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list v-else class="pt-2">
      <div class="px-3">
        <v-btn
          block
          large
          text
          class="primary"
          to="/post-problem"
        >Post Task</v-btn>
      </div>
      <v-list-item to="/tasks" color="primary" class="mt-2">
        <v-icon class="pr-5">work</v-icon>
        <v-list-item-title>Tasks</v-list-item-title>
      </v-list-item>
      <!-- <v-list-item to="/transactions" color="primary">
          <v-icon class="pr-5">account_balance_wallet</v-icon>
        <v-list-item-content>
          <v-list-item-title>My Wallet</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <!-- <v-list-item to="/lawyers">
        <v-list-item-action>
          <v-icon>assignment_ind</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Lawyers</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <v-list-group>
        <template v-slot:activator>
          <v-icon class="pr-1">mdi-cog</v-icon>
          <v-list-item color="primary">
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item>
        </template>
          <div v-for="item in settings" :key="item.title">
            <v-list-item :to="item.to">
              <v-list-item-action />
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
      </v-list-group>
      <v-list-item  @click="logOut" class="error">
        <v-icon class="white--text pr-5">exit_to_app</v-icon>
        <v-list-item-content>
          <v-list-item-title class="white--text">Sign Out</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data () {
    return {
      drawer: false,
      findJobs: [
        { title: 'All Jobs', to: '/jobs' },
        { title: 'My Proposals', to: '/a/proposals' }
      ],
      myJobs: [
        { title: 'Ongoings', to: '/ongoings' },
        { title: 'Completed', to: '/a/completed' },
        { title: 'Disptues', to: '/disputes' }
      ],
      myProblems: [
        { title: 'Pendings', to: '/pendings' },
        { title: 'Ongoings', to: '/ongoings' },
        { title: 'Completed', to: '/a/completed' },
        { title: 'Disputes', to: '/disputes' }
      ],
      settings: [
        { title: 'Profile', to: '/settings/profile' },
        { title: 'Password', to: '/settings/change-password' },
        { title: 'Contacts', to: '/settings/contacts' }
      ]
    }
  },
  methods: {
    showSideBar () {
      this.drawer = !this.drawer
    },
    logOut () {
      this.$store.dispatch('auth/DELETE_TOKEN')
        .then(res => {
          this.$router.push('/')
          this.$store.dispatch('user/DELETE_USER_STATE')
          localStorage.removeItem('loggedInName')
          localStorage.removeItem('isLawyer')
          localStorage.removeItem('lawyerId@view')
        })
    }
  },
  computed: {
    user () {
      return this.$store.getters['user/user'].user
    },
    loggedIn () {
      return this.$store.getters['auth/loggedIn']
    }
  },
  filters: {
    slice: function (value) {
      if (!value) return ''
      if (/\s/.test(value)) {
        value = value.toString()
        return value.substr(0, value.indexOf(' '))
      } else {
        return value
      }
    }
  }
}
</script>
