import NotificationService from '@/services/NotificationService'

const state = {
  isFetching: false,
  notif: false,
  notifications: [],
  notifCount: 0,
  notifType: ''
}

const getters = {
  isFetching (state) {
    return state.isFetching
  },
  notifications (state) {
    return state.notifications
  },
  notifCount (state) {
    return state.notifCount
  },
  notifType (state) {
    return state.notifications.notifType
  },
  notif (state) {
    return state.notif
  }
}

const mutations = {
  SET_ISFETCHING (state, payload) {
    state.isFetching = payload
  },
  SET_NOTIFICATIONS (state, payload) {
    state.notifications = payload
  },
  SET_NOTIFICATIONS_COUNT (state, payload) {
    if (payload.length > 0) {
      for (let i = 0; i < payload.length; i++) {
        if (payload[i].isNotified === false) {
          state.notifCount++
          if (state.notifCount > 0) {
            state.notif = true
          }
        }
      }
    } else {
      state.notifCount = 0
    }
  },
  SET_NOTIFIED (state, payload) {
    state.notifCount = payload
    state.notif = false
  },
  SET_REALTIME_MOBILE (state, payload) {
    state.notifCount += payload
    state.notif = true
  },
  DELETE_NOTIFICATIONS (state) {
    state.notifications = []
    state.notif = false
    state.notifCount = 0
  }
}

const actions = {
  getNotifications ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('SET_ISFETCHING', true)
      NotificationService.getNotifications()
        .then(res => {
          if (res.data.length > 0)
          commit('SET_NOTIFICATIONS', res.data[0].notifications)
          resolve(res.data[0].notifications)
        })
        .catch(err => {
          reject(err)
        })
        .finally(() => {
          commit('SET_ISFETCHING', false)
        })
    })
  },
  getNotifCount ({ commit }) {
    return new Promise((resolve, reject) => {
      NotificationService.getNotifications()
        .then(res => {
          commit('SET_NOTIFICATIONS_COUNT', res.data[0].notifications)
          resolve()
        })
    })
  },
  isNotified ({ commit }) {
    return new Promise((resolve, reject) => {
      NotificationService.isNotified()
        .then(res => {
          commit('SET_NOTIFIED', 0)
          resolve()
        })
    })
  },
  clearNotifications ({ commit }) {
    return new Promise((resolve, reject) => {
      NotificationService.deleteNotifications()
        .then(res => {
          commit('DELETE_NOTIFICATIONS')
          resolve()
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}