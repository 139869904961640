import FindLawyerService from '../../services/FindLawyerService'

const state = {
  isFetching: false,
  lawyers: {},
  selectedLawyer: {},
  pages: 0,
  page: 1,
  perPage: 10,
  name: '',
  province: '',
  city: '',
  sort: 'Descending'
}

const getters = {
  lawyers (state) {
    return state.lawyers
  },
  selectedLawyer (state) {
    return state.selectedLawyer
  },
  page (state) {
    return state.page
  },
  pages (state) {
    return state.pages
  },
  isFetching (state) {
    return state.isFetching
  }
}

const mutations = {
  SET_PAGINATION (state, res) {
    state.pages = res.pages
    state.page = res.page
    state.lawyers = res.docs
  },
  SET_SELECTEDLAWYER (state, payload) {
    state.selectedLawyer = payload
  },
  SET_PAGE (state, payload) {
    state.page = payload
  },
  SET_FILTER (state, payload) {
    state.name = payload.name
    state.province = payload.province
    state.city = payload.city
    state.sort = payload.sort
  },
  SET_ISFETCHING (state, payload) {
    state.isFetching = payload
  }
}

const actions = {
  getLawyers ({ commit, state }, payload) {
    commit('SET_ISFETCHING', true)
    commit('SET_PAGE', payload)
    return new Promise((resolve, reject) => {
      FindLawyerService.getLawyers({
        page: state.page,
        perPage: state.perPage,
        name: state.name,
        province: state.province,
        city: state.city,
        sort: state.sort
      })
        .then(res => {
          commit('SET_PAGINATION', res.data)
        })
        .catch(err => {
          reject(err)
        })
        .finally(() => {
          commit('SET_ISFETCHING', false)
        })
    })
  },
  setSelectedLawyer ({ commit }, payload) {
    commit('SET_SELECTEDLAWYER', payload)
  },
  filterLawyerList ({ commit, dispatch }, payload) {
    commit('SET_FILTER', payload)
    return new Promise((resolve, reject) => {
      dispatch('getLawyers', 1)
        .catch(err => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
