import AuthService from '../../services/AuthService'
import UserService from '../../services/UserService'
import axios from 'axios'
import VueCookie from 'vue-cookie'

const state = {
  token: VueCookie.get('user_token') || null
}

const getters = {
  loggedIn (state) {
    return state.token !== null
  }
}

const mutations = {
  SET_TOKEN (state, token) {
    state.token = token
  },
  DELETE_TOKEN (state) {
    state.token = null
  }
}

const actions = {
  SET_TOKEN (context, payload) {
    return new Promise((resolve, reject) => {
      AuthService.loginUser({
        email: payload.email,
        password: payload.password
      })
        .then(res => {
          const token = res.data.token
          localStorage.setItem('isLawyer', res.data.user.isLawyer)
          VueCookie.set('user_token', token)
          VueCookie.set('userId', res.data.user._id)
          context.commit('SET_TOKEN', token)
          axios.defaults.headers.common['Authorization'] = VueCookie.get('user_token')
          context.dispatch('user/SET_USER', res.data, { root: true })
          resolve(res.data.user.isLawyer)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  setInitialGoogleReg (context, payload) {
    return new Promise((resolve, reject) => {
      UserService.callBackGoogle({
        code: payload.code,
        scope: payload.scope,
        authuser: payload.authuser
      })
        .then(res => {
          if (res.data.token) {
            VueCookie.set('user_token', res.data.token)
            context.commit('SET_TOKEN', res.data.token)
            localStorage.setItem('isLawyer', res.data.user.isLawyer)
            axios.defaults.headers.common['Authorization'] = VueCookie.get('user_token')
            VueCookie.set('userId', res.data.user._id)
            context.dispatch('user/SET_USER', res.data, { root: true })
            resolve(res.data.user)
          } else {
            VueCookie.set('userId', res.data.user._id)
            context.dispatch('user/SET_USER', res.data, { root: true })
            resolve(res.data.user)
          }
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  setInitialFacebook (context, payload) {
    return new Promise((resolve, reject) => {
      UserService.callBackFaceBook({
        code: payload.code
      })
      .then(res => {
        if (res.data.token) {
          VueCookie.set('user_token', res.data.token)
          context.commit('SET_TOKEN', res.data.token)
          localStorage.setItem('isLawyer', res.data.user.isLawyer)
          axios.defaults.headers.common['Authorization'] = VueCookie.get('user_token')
          VueCookie.set('userId', res.data.user._id)
          context.dispatch('user/SET_USER', res.data, { root: true })
          resolve(res.data.user)
        } else {
          VueCookie.set('userId', res.data.user._id)
          context.dispatch('user/SET_USER', res.data, { root: true })
          resolve(res.data.user)
        }
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  setOAuthUserLawyer ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UserService.finalStepOAuthLawyer(payload.formData)
        .then(res => {
          VueCookie.set('user_token', res.data.token)
          commit('SET_TOKEN', res.data.token)
          localStorage.setItem('isLawyer', res.data.user.isLawyer)
          axios.defaults.headers.common['Authorization'] = VueCookie.get('user_token')
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  setOAuthUserClient ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UserService.finalStepOAuthClient(payload)
        .then(res => {
          VueCookie.set('user_token', res.data.token)
          commit('SET_TOKEN', res.data.token)
          localStorage.setItem('isLawyer', res.data.user.isLawyer)
          axios.defaults.headers.common['Authorization'] = VueCookie.get('user_token')
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getCsrfToken ({ commit }) {
    return new Promise((resolve, reject) => {
      AuthService.getCsrfToken()
        .then(res => {
          axios.defaults.headers.common['X-CSRF-Token'] = res.data.csrfToken
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  DELETE_TOKEN (context) {
    if (context.getters.loggedIn) {
      try {
        VueCookie.delete('user_token')
        VueCookie.delete('userId')
        context.commit('DELETE_TOKEN')
      } catch (err) {
        console.log(err)
      }
    } else {
      console.log('Found Error')
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
