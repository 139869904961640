import Vue from 'vue'
import Router from 'vue-router'
import LawyerRoutes from './lawyer'
import ClientRoutes from './client'
import AllRoleRoutes from './allRoles'

Vue.use(Router)

const baseRoutes = [
  {
    path: '/404',
    alias: '*',
    name: 'PageNotFound',
    component: () => import('../views/errors/404')
  },
  {
    path: '/403',
    name: 'PageNoAccess',
    component: () => import('../views/errors/403')
  },
  {
    path: '/',
    name: 'Home',
    meta: { guestAuth: true },
    component: () => import('../views/Home')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/auth/Login'),
    meta: { guestAuth: true }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/auth/Register'),
    meta: { guestAuth: true }
  },
  {
    path: '/final-step',
    name: 'FinalStep',
    component: () => import('../views/auth/FinalStep.vue')
  },
  {
    path: '/terms-and-conditions',
    name: 'Terms-And-Conditions',
    component: () => import('../views/information/Terms-And-Conditions')
  },
  {
    path: '/privacy-policy',
    name: 'Privacy-Policy',
    component: () => import('../views/information/Privacy-Policy')
  },
  {
    path: '/fees-and-charges',
    name: 'Fees-and-Charges',
    component: () => import('../views/information/Fees-and-Charges')
  },
  {
    path: '/about-us',
    name: 'About-Us',
    component: () => import('../views/information/About-Us.vue')
  },
  // {
  //   path: '/contact-us',
  //   name: 'Contact-Us',
  //   component: () => import('../views/information/Contact-Us.vue')
  // },
  {
    path: '/how-it-works',
    name: 'How-It-Works',
    component: () => import('../views/information/How-It-Works.vue')
  },
  {
    path: '/how-to-pay',
    name: 'How-To-Pay',
    component: () => import('@/components/IndexComponents/PaymentMethods.vue')
  },
  {
    path: '/verification/:userId/:verificationToken',
    name: 'Verification',
    props: true,
    component: () => import('../views/Verification.vue')
  },

  // SETTING ROUTES
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings'),
    redirect: '/profile',
    meta: { requiresAuth: true },
    children: [
      {
        path: '/settings/profile',
        meta: { requiresAuth: true },
        component: () => import('../components/SettingsComponents/Profile')
      },
      {
        path: '/settings/educational',
        meta: { requiresAuth: true },
        component: () => import('../components/SettingsComponents/EducationalBackground')
      },
      {
        path: '/settings/contacts',
        meta: { requiresAuth: true },
        component: () => import('../components/SettingsComponents/Contacts')
      },
      {
        path: '/settings/change-password',
        meta: { requiresAuth: true },
        component: () => import('../components/SettingsComponents/ChangePassword')
      }
    ]
  }
]

const routes = baseRoutes.concat(LawyerRoutes, ClientRoutes, AllRoleRoutes)

export default new Router({
  scrollBehavior () {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
