import NotificationService from '@/services/NotificationService'

export default {
  data () {
    return {
      isDeleting: false,
      notifMenu: false,
      notif: false,
      none: false,
      closed: false,
      on: '',
      notifications: [],
      notifCount: 0,
      notifiedCount: 0,
      transactionTypes: [
        'Approved Deposit',
        'Denied'
      ]
    }
  },
  methods: {
    getNotifications () {
      NotificationService.getNotifications()
        .then(res => {
          if (res.data.length > 0) {
            this.notifications = res.data[0].notifications
            for (let i = 0; i < res.data[0].notifications.length; i++) {
              if (res.data[0].notifications[i].isNotified === false) {
                this.notifCount++
                if (this.notifCount > 0) {
                  this.notif = true
                }
              }
              else if (res.data[0].notifications[i].isNotified === true) {
                this.notifiedCount++
              }
            }
          }
        })
    },
    isNotified () {
      this.notifMenu = true
      NotificationService.isNotified()
        .then(() => {
          this.notifCount = 0
          this.notif = false
        })
    },
    clearNotifications () {
      this.closed = true
      NotificationService.clearAllNotifs()
        .then(() => {
          this.notifications = []
          this.notif = false
          this.notifCount = 0
        })
    },
    deleteNotif (notifId, index) {
      this.closed = false
      this.isDeleting = true
      NotificationService.deleteNotif({ notifId })
        .then(() => {
          this.notifications.splice(index, 1)
        })
        .catch(() => {
          this.$swal({
            type: 'error',
            text: 'Something went wrong with the server.',
            confirmButtonColor: this.$vuetify.theme.primary,
            onOpen: () => { document.activeElement.blur() }
          })
        })
        .finally(() => {
          this.isDeleting = false
        })
    },
    redirectNotification (notifType, adviceId, disputeId, problemId) {
      this.closed = true
      if (notifType === 'Hired Lawyer' || notifType === 'Legal Advice Paid' || notifType === 'Board Reply') {
        this.$router.push({ name: 'Board', params: { boardId: adviceId } })
      }
      else if (notifType === 'Legal Advice Proposal') {
        this.$router.push({ name: 'OpenJob', params: { problemId: problemId } })
      }
      else if (notifType === 'Disputed Lawyer' || notifType === 'Dispute Award' || notifType === 'Dispute Response') {
        this.$router.push({ name: 'Dispute-ViewOne', params: { disputeId: disputeId } })
      }
      else if (notifType === 'Advice Completed') {
        this.$router.push({ name: 'Overview' })
      }
      else if (notifType === 'Override Task' && this.isLawyer) {
        this.$router.push({ name: 'Transactions' })
      }
      else if (notifType === 'Override Task' && !this.isLawyer) {
        this.$router.push({ name: 'Board', params: { boardId: adviceId } })
      }
      else if (notifType.indexOf(this.transactionTypes)) {
        this.$router.push({ name: 'Transactions' })
      }
    },
    hiredLawyer () {
      this.socket.on('hire-lawyer', data => {
        this.notifCount += data.notif
        this.notif = true
        this.notifications.unshift({
          legalAdviceId: data.boardId,
          notifType: data.notifType,
          client: {
            fullName: data.user,
            imageUrl: data.imageUrl
          },
          problem: {
            _id: data.problemId,
            title: data.problemTitle,
            isAnonymous: data.isAnonymous
          }
        })
        let userString = data.isAnonymous ? 'Anonymous User' : data.user
        this.$toast.open({
          type: 'success',
          message: `You have been hired by ${userString} as his lawyer for the advice you proposed`,
          duration: 6000
        })
      })
    },
    disputeLawyer () {
      this.socket.on('dispute-lawyer', data => {
        this.notifCount += data.notif
        this.notif = true
        this.notifications.unshift({
          disputeId: data.disputeId,
          notifType: data.notifType,
          problem: data.problem,
          client: {
            fullName: data.user,
            imageUrl: data.imageUrl
          }
        })
        let userString = !data.problem.isAnonymous ? data.user : 'Anonymous User'
        this.$toast.open({
          type: 'error',
          message: `${userString} has filed a dispute.`,
          duration: 6000
        })
      })
    },
    disputeResponse () {
      this.socket.on('dispute-reply', data => {
        this.notifCount += 1
        this.notif = true
        this.notifications.unshift({
          disputeId: data.disputeId,
          notifType: data.notifType
        })
        this.$toast.open({
          type: 'error',
          message: 'The lawyer has responded to your dispute.',
          duration: 6000
        })
      })
    },
    approvedDeposit () {
      this.socket.on('approve-deposit', data => {
        this.notifCount += data.notif
        this.notifications.unshift({
          transaction: data.transaction,
          notifType: data.notifType
        })
        this.notif = true
        this.$toast.open({
          type: 'info',
          message: 'Your deposit has been approved by our team and has been added to your funds.',
          duration: 6000
        })
      })
    },
    approvedPayment () {
      this.socket.on('approve-payment', data => {
        this.notifCount += data.notif
        this.notif = true
        this.notifications.unshift({
          legalAdviceId: data.legalAdvice,
          transaction: data.transaction,
          notifType: data.notifType
        })
        this.$toast.open({
          type: 'info',
          message: 'Your legal advice payment has been approved by our team. Please wait for your lawyer to impose his advice.',
          duration: 6000
        })
      })
    },
    declinedTransaction () {
      this.socket.on('denied-transaction', data => {
        this.notifCount += data.notif
        this.notifications.unshift({
          transaction: data.transaction,
          notifType: data.notifType
        })
        this.notif = true
        this.$toast.open({
          type: 'error',
          message: 'Your transaction has been denied. Please check on your transactions to know the details.',
          duration: 6000
        })
      })
    },
    approvedCredits () {
      this.socket.on('approve-credits', data => {
        this.notifCount += data.notif
        this.notifications.unshift({
          transaction: data.transaction,
          notifType: data.notifType
        })
        this.notif = true
        this.$toast.open({
          type: 'info',
          message: 'You have successfully purchased credits and has added to your credits.',
          duration: 6000
        })
      })
    },
    declinedCredits () {
      this.socket.on('denied-credits', data => {
        this.notifCount += data.notif
        this.notifications.unshift({
          transaction: data.transaction,
          notifType: data.notifType
        })
        this.notif = true
        this.$toast.open({
          type: 'error',
          message: 'Your purchase to add credits has been denied. Please check on your transaction to know the details.',
          duration: 6000
        })
      })
    },
    approvedCashout () {
      this.socket.on('approved-cashout', data => {
        this.notifCount += data.notif
        this.notifications.unshift({
          notifType: data.notifType,
          transaction: data.transaction
        })
        this.notif = true
        this.$toast.open({
          type: 'info',
          message: 'Your cashout has been approved. Please go to My Wallet to check the details.',
          duration: 6000
        })
      })
    },
    declinedCashout () {
      this.socket.on('declined-cashout', data => {
        this.notifCount += data.notif
        this.notifications.unshift({
          notifType: data.notifType,
          transaction: data.transaction
        })
        this.notif = true
        this.$toast.open({
          type: 'error',
          message: 'Your cashout has been denied. Please go to My Wallet to check the details.',
          duration: 6000
        })
      })
    },
    disputeComplainant () {
      this.socket.on('dispute-complainant', data => {
        this.notifCount += data.notif
        this.notifications.unshift({
          problem: data.problem,
          awardedTo: data.awardedTo,
          notifType: data.notifType,
          disputeId: data.disputeId
        })
        this.notif = true
        let userString = data.awardedTo === 'Defendant' ? 'to the defendant.' : 'to you.'
        this.$toast.open({
          message: `The dispute has been awarded to ${userString}`,
          type: data.awardedTo === 'Defendant' ? 'error' : 'info',
          duration: 6000
        })
      })
    },
    disputeDefendant () {
      this.socket.on('dispute-defendant', data => {
        this.notifCount += data.notif
        this.notifications.unshift({
          problem: data.problem,
          awardedTo: data.awardedTo,
          notifType: data.notifType,
          disputeId: data.disputeId
        })
        this.notif = true
        let userString = data.awardedTo === 'Complainant' ? 'to the complainant.' : 'to you.'
        this.$toast.open({
          message: `The dispute has been awarded to ${userString}`,
          type: data.awardedTo === 'Complainant' ? 'error' : 'info',
          duration: 6000
        })
      })
    },
    approveLegalAdvicePayment () {
      this.socket.on('legal-payment', data => {
        this.notifCount += data.notif
        this.notif = true
        this.notifications.unshift({
          notifType: data.notifType,
          transaction: data.transaction,
          legalAdviceId: data.legalAdvice,
          problem: data.problem,
          client: {
            fullName: data.clientName
          }
        })
        let userString = !data.problem.isAnonymous ? data.clientName : 'Anonymous User'
        this.$toast.open({
          type: 'info',
          message: `${userString} has paid for your Legal Advice charge.`,
          duration: 6000
        })
      })
    },
    completeJob () {
      this.socket.on('complete-job', data => {
        this.notifCount += data.notif
        this.notifications.unshift({
          notifType: data.notifType,
          transaction: data.transaction,
          client: data.client,
          problem: data.problem
        })
        this.notif = true
        let userString = !data.problem.isAnonymous ? data.client.fullName : 'Anonymous User'
        this.$toast.open({
          type: 'info',
          message: `${userString} has completed the job.`,
          duration: 6000
        })
      })
    },
    sendProposal () {
      this.socket.on('sendProposal', data => {
        let hasNotif = false
        this.notifCount += 1
        for (let i = 0; i < this.notifications.length; i++) {
          if (("problem" in this.notifications[i])) {
            if (this.notifications[i].problem._id === data.problem._id) {
              this.notifications[i].proposalCount++
              this.notifications[i].createdAt = new Date().toJSON()
              hasNotif = true
              this.notif = true
              this.notifications.sort((a,b) => {
                return new Date(b.createdAt) - new Date(a.createdAt)
              })
            }
          }
        }
        if (!hasNotif) {
          this.notifications.unshift({
            proposalCount: 1,
            notifType: data.notifType,
            problem: data.problem
          })
          this.notif = true
          this.$toast.open({
            type: 'success',
            message: 'A lawyer has proposed a bid for the task you posted.',
            duration: 6000
          })
        }
      })
    },
    overrideTaskClient () {
      this.socket.on('override-client', data => {
        this.notifCount += 1
        this.notifications.unshift({
          notifType: data.notifType,
          legalAdviceId: data.legalAdvice,
          transaction: {
            amount: data.amount
          }
        })
        this.notif = true
      })
    },
    overrideTaskLawyer () {
      this.socket.on('override-lawyer', data => {
        this.notifCount += 1
        this.notifications.unshift({
          notifType: data.notifType,
          legalAdviceId: data.legalAdvice,
          transaction: {
            amount: data.amount
          }
        })
        this.notif = true
      })
    }
  },
  computed: {
    isLawyer () {
      return this.$store.getters['user/user'].user.isLawyer
    }
  },
  filters: {
    slice: function (value) {
      if (!value) return ''
      if (/\s/.test(value)) {
        value = value.toString()
        return value.substr(0, value.indexOf(' '))
      } else {
        return value
      }
    }
  },
  created () {
    this.getNotifications()
    this.socket.emit('login-users', { email: this.$store.getters['user/user'].user.email })
  },
  mounted () {
    // All Users Notification
    this.approvedCashout()
    this.declinedCashout()
    this.disputeComplainant()
    this.disputeDefendant()

    // Lawyer Notifications
    this.approvedCredits()
    this.declinedCredits()
    this.hiredLawyer()
    this.disputeLawyer()
    this.approveLegalAdvicePayment()
    this.completeJob()
    this.overrideTaskLawyer()

    // Client Notifications
    this.approvedDeposit()
    this.approvedPayment()
    this.declinedTransaction()
    this.sendProposal()
    this.overrideTaskClient()
    this.disputeResponse()
  }
}