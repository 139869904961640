import UserService from '@/services/UserService'
import axios from 'axios'
import VueCookie from 'vue-cookie'

const state = {
  user: {
    reviews: 0.0,
    imageUrl: 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png',
    isVerified: true
  },
  isFetching: ''
}

const getters = {
  user (state) {
    return state
  },
  userCredits (state) {
    return state.user.credits
  },
  isFetching (state) {
    return state.isFetching
  }
}

const mutations = {
  SET_FETCHING (state, payload) {
    state.isFetching = payload
  },
  SET_LOGGED_USER (state, payload) {
    if (payload.user.isLawyer) {
      state.user = payload.user
      if (!payload.user.imageUrl) {
        state.user.imageUrl = 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'
      }
    } else {
      state.user = payload.user
      if (!payload.user.imageUrl) {
        state.user.imageUrl = 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'
      }
    }
  },
  UPDATE_INFORMATION (state, payload) {
    state.user = payload
  },
  UPDATE_CREDITS (state) {
    state.user.credits = state.user.credits - 1
  },
  SET_EMAIL (state, payload) {
    state.user.email = payload
  },
  SET_PHONE (state, payload) {
    state.user.phone = payload
  },
  SET_PROVINCE (state, payload) {
    state.user.province = payload
  },
  SET_CITY (state, payload) {
    state.user.city = payload
  },
  SET_BARANGAY (state, payload) {
    state.user.barangay = payload
  },
  SET_HOUSE_AND_STREET (state, payload) {
    state.user.houseNumAndStreet = payload
  },
  SET_POSTAL_CODE (state, payload) {
    state.user.postalCode = payload
  },
  SET_LAW_OFFICE (state, payload) {
    state.user.lawOffice = payload
  },
  SET_EDUCATIONAL_DEGREE (state, payload) {
    state.user.education.push(payload)
  },
  SET_SPECIALIZATION (state, payload) {
    state.user.specialization = payload
  },
  SET_DESCRIPTION (state, payload) {
    state.user.description = payload
  },
  SET_PHONE_NUMBER (state, payload) {
    if (!payload.control) {
      state.user.contacts.push(payload.number)
    } else {
      state.user.contacts.splice(payload.index, 1, payload.number)
    }
  },
  SET_COMPLETE_ADDRESS (state, payload) {
    state.user.completeAddress = payload
  },
  SET_EDUCATION (state, payload) {
    if (!payload.control) {
      state.user.education.push(payload)
    } else {
      state.user.education[payload.index].course = payload.education.course
      state.user.education[payload.index].school = payload.education.school
      state.user.education[payload.index].start = payload.education.start
      state.user.education[payload.index].end = payload.education.end
    }
  },
  SET_IMAGE (state, payload) {
    state.user.imageUrl = payload
  },
  VERIFY_USER_EMAIL (state, payload) {
    state.user.isVerified = payload
  },
  DELETE_USER_STATE (state) {
    state.user = {}
  },
  DELETE_PHONE_NUMBER (state, payload) {
    state.user.contacts.splice(payload.index, 1)
  },
  DELETE_EDUCATION (state, payload) {
    state.user.education.splice(payload.index, 1)
  }
}

const actions = {
  SET_LOGGED_USER ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.defaults.headers.common['Authorization'] = VueCookie.get('user_token')
      UserService.getLoggedInUser()
        .then(res => {
          commit('SET_LOGGED_USER', res.data)
          VueCookie.set('userId', res.data.user._id)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  SET_USER ({ commit }, payload) {
    commit('SET_LOGGED_USER', payload)
  },
  setEducationalDegree ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UserService.addEducation({ education: payload })
      .then(res => {
        commit('SET_EDUCATIONAL_DEGREE', payload)
        console.log(res)
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
    })
    
  },
  UPDATE_INFORMATION ({ state, commit }) {
    return new Promise((resolve, reject) => {
      UserService.updateInfo({
        user: state.user
      })
        .then(res => {
          if (res.status === 200) {
            commit('UPDATE_INFORMATION', state.user)
            resolve(res.data)
          } else {
            resolve(res)
          }
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  UPDATE_PROFILE_PICTURE ({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      UserService.updateProfilePicture({
        payload
      })
        .then(res => {
          commit('SET_IMAGE', res.data.imageUrl)
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  UPDATE_PHONE_NUMBER ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UserService.updatePhoneNumber({
        contact: payload
      })
        .then(res => {
          commit(payload.control === 'delete' ? 'DELETE_PHONE_NUMBER' : 'SET_PHONE_NUMBER', payload)
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  UPDATE_COMPLETE_ADDRESS ({ state }) {
    return new Promise((resolve, reject) => {
      UserService.updateCompleteAddress({
        city: state.user.city,
        province: state.user.province,
        barangay: state.user.barangay,
        houseNumAndStreet: state.user.houseNumAndStreet,
        postalCode: state.user.postalCode
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  UPDATE_EDUCATION ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UserService.updateEducation({
        education: payload
      })
        .then(res => {
          if (res.status === 200) {
            commit(payload.control === 'delete' ? 'DELETE_EDUCATION' : 'SET_EDUCATION', payload)
            resolve(res.data)
          } else {
            alert(res)
          }
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  verifyUserEmail ({ commit }, payload) {
    commit('VERIFY_USER_EMAIL', payload)
  },
  DELETE_USER_STATE ({ commit }) {
    commit('DELETE_USER_STATE')
    localStorage.removeItem('loggedInName')
    localStorage.removeItem('isLawyer')
    localStorage.removeItem('lawyerId@view')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
