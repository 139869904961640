import Api from '@/services/Api'

export default {
  createLegalAdviceBoard (params) {
    return Api().post('/api/legalAdvices/create', params.board)
  },
  getLegalAdviceBoard (params) {
    return Api().get(`/api/legalAdvices/${params}`)
  },
  getImages (params) {
    return Api().get(`/api/legalAdvices/images/${params}`)
  },
  hideAdvice (params) {
    return Api().patch(`/api/legalAdvices/hide-advice/${params.adviceId}`)
  },
  sendMessage (params) {
    return Api().patch(`/api/legalAdvices/send-message/${params.adviceId}`, params.formData)
  },
  sendFile (params) {
    return Api().patch(`/api/legalAdvices/send-file/${params.adviceId}`, params.formData)
  },
  cancelRefundTask (params) {
    return Api().patch('/api/legalAdvices/cancel-task', params)
  }
}
