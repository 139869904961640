<template>
  <v-app id="app" class="header">
    <Toolbar
      v-if="this.$store.getters['user/user'].user.isLawyer === false | this.$store.getters['user/user'].user.isLawyer === true &&
      [
        'Register',
        'About-Us',
        'Contact-Us',
        'Fees-and-Charges',
        'How-It-Works',
        'Privacy-Policy',
        'Terms-And-Conditions',
        'How-To-Pay',
        'FinalStep'
      ].indexOf($route.name) < 0"
    />
    <InformationToolbar v-if="[
        'About-Us',
        'Contact-Us',
        'Fees-and-Charges',
        'How-It-Works',
        'Privacy-Policy',
        'Terms-And-Conditions',
        'How-To-Pay'
      ].indexOf($route.name) >= 0"
    />
      <!-- <InformationNavBar v-else/>
    </div> -->
    
    <v-main style="background-color: #F0F2F5;">
      <v-container fluid pa-0 >
        <PleaseVerify v-if="!$store.getters['user/user'].user.isVerified &&
          $store.getters['control/showPleaseVerify'] &&
          $store.getters['auth/loggedIn'] &&
          [
            'Register',
            'About-Us',
            'Contact-Us',
            'Fees-and-Charges',
            'How-It-Works',
            'Privacy-Policy',
            'Terms-And-Conditions',
            'How-To-Pay'
          ].indexOf($route.name) < 0"
        />
        <router-view v-if="!$store.getters['errors/hasError500']"></router-view>
        <ErrorPage500 v-else />
      </v-container>
    </v-main>
    <Footer v-if="[ 'MobileConversation' ].indexOf($route.name) < 0" />
  </v-app>
</template>

<script>
import Toolbar from './components/NavigationComponents/Toolbar'
import InformationToolbar from './components/NavigationComponents/Information-Toolbar'
import Footer from './components/NavigationComponents/Footer'
import ErrorPage500 from '@/views/errors/500'
import PleaseVerify from '@/components/UserComponent/PleaseVerify'


export default {
  name: 'App',
  components: {
    Toolbar,
    InformationToolbar,
    Footer,
    ErrorPage500,
    PleaseVerify
  },
  data () {
    return {
      show: true,
      hasError: false
    }
  },
  created () {
    if (this.$cookie.get('user_token')) {
      this.$store.dispatch('auth/getCsrfToken')
      this.$store.dispatch('user/SET_LOGGED_USER')
        .catch(() => {
          this.$store.dispatch('auth/DELETE_TOKEN')
            .then(res => {
              this.$store.dispatch('user/DELETE_USER_STATE')
              this.$store.dispatch('errors/setError500', true)
            })
          this.hasError = true
        })
    }
  }
}
</script>

<style>
  body {
    font-family: "Roboto";
  }
  .v-sheet.v-card {
    box-shadow: 0 1px 3px 0 hsla(0, 0%, 0%, 0.1) !important;
  }
  .dropShadow {
    box-shadow: 0 10px 3px 0 hsla(0, 0%, 0%, 0.3)
  }
  .lightBlack {
    background: hsl(0, 0%, 97%)
  }
  .testGrid {
    background: #798080;
  }
  .iconDarken {
    color: #ffffff;
  }
  .v-btn__content{
    text-transform: none;
  }
  .border-sheet {
    border-radius: 0px !important;
  }
  .action-bottom-border {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .header-top-border {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }
  .contacts-margin {
    margin: 0px 8rem;
  }
  .semi-black {
    color: #212F3E;
  }
  .m-grey {
    color: #8395A7;
  }
  .hard-green {
    color: #379e70;
  }
  .notHovered {
    text-decoration: none;
    color: #212121;
  }
  .viewMorePointer:hover {
    cursor: pointer;
    color: #5F27CD;
  }
  .pointer:hover {
    cursor: pointer;
  }
  .imgBorderRadius {
    border-radius: 2%;
  }
  .tile {
    margin: 5px;
    border-radius: 4px;
  }
  .tile:hover {
    background: #EEEEEE;
    padding: 4px, 22px, 4px, 22px;
  }
  .mainGrey {
    background-color: #f5f5f5;
  }
  .taskTile {
    border-radius: 4px;
  }
  .taskTile:hover {
    background: #EEEEEE;
  }
  .justifyText {
    text-align: justify;
  }
  .v-btn__content{
    text-transform: none !important;
  }
</style>
