export default [
  // OVERVIEW ROUTE
  {
    path: '/overview',
    name: 'Overview',
    props: true,
    meta: { requiresAuth: true, lawyerAuth: true },
    component: () => import('../views/lawyer/Overview')
  },
  {
    path: '/topup',
    name: 'TopUp',
    props: true,
    meta: { requiresAuth: true, lawyerAuth: true },
    component: () => import('../views/lawyer/TopUp')
  },
  // JOBS ROUTES
  {
    path: '/jobs',
    name: 'Jobs',
    meta: { requiresAuth: true, lawyerAuth: true },
    component: () => import('../views/lawyer/jobs/ViewAll')
  },
  // PROPOSALS ROUTES
  {
    path: '/a/proposals',
    name: 'Proposals',
    meta: { requiresAuth: true, lawyerAuth: true },
    component: () => import('../views/lawyer/Proposals')
  }
]
