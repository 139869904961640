import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import proposals from './modules/proposals'
import user from './modules/user'
import findLawyer from './modules/findLawyer'
import board from './modules/board'
import completedBoard from './modules/completedBoard'
import transactions from './modules/transactions'
import disputes from './modules/disputes'
import jobs from './modules/jobs'
import legalAdvice from './modules/legalAdvice'
import control from './modules/control'
import errors from './modules/errors'
import notifications from './modules/notifications'

Vue.use(Vuex)

export const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    auth,
    jobs,
    legalAdvice,
    proposals,
    user,
    findLawyer,
    board,
    completedBoard,
    transactions,
    disputes,
    control,
    errors,
    notifications
  }
})
