import moment from 'moment'

export default {
  filters: {
    phCurrency (value) {
      if (!value) {
        value = 0
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      } else {
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      }
    },
    amountReceived (value) {
      if (!value) {
        value = 0
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      } else {
        return (value - (value * parseFloat(process.env.VUE_APP_SERVICE_FEE))).toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      }
    },
    subtractFee (value) {
      if (!value) {
        value = 0
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      } else {
        return value * process.env.VUE_APP_SERVICE_FEE
      }
    },
    websiteFee (value) {
      if (!value) {
        value = 0
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      } else {
        let originalAmount = value / (1 - process.env.VUE_APP_SERVICE_FEE)
        return (originalAmount * process.env.VUE_APP_SERVICE_FEE).toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      }
    },
    proposedAmount (value) {
      return (value / (1 - process.env.VUE_APP_SERVICE_FEE)).toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
    },
    cashoutFee (value) {
      if (!value) {
        value = 0
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      } else {
        return (value * process.env.VUE_APP_CASHOUT_FEE + 100).toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      }
    },
    netCashout (value) {
      return (value - (value * process.env.VUE_APP_CASHOUT_FEE + 100)).toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
    },
    dateToWords (value) {
      return value ? moment(value).format('DD MMMM YYYY') : ''
    },
    dateToNumerical (value) {
      return value ? moment(value).format('L') : ''
    },
    localDate: function (value) {
      return value ? moment(value).format('MMM DD, YYYY') : ''
    }
  }
}
