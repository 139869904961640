import Vue from 'vue'
// import './plugins/vuetify'
import App from './App.vue'
import router from './routes/index'
import { store } from './store/store'
import Vuetify from 'vuetify/lib'
import VeeValidate from 'vee-validate'
import Philippines from 'philippines/provinces'
import VueSweetalert2 from 'vue-sweetalert2'
import VueCookie from 'vue-cookie'
import VueContentPlaceholders from 'vue-content-placeholders'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import VueToast from 'vue-toast-notification'
//import 'vue-toast-notification/dist/theme-default.css'
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(Vuetify)

Vue.use(VueSweetalert2)
Vue.use(VueCookie)
Vue.use(Philippines)
Vue.use(VeeValidate)
Vue.use(VueContentPlaceholders)
Vue.use(VueToast, {
  position: 'bottom-left'
});

Vue.component('VueCropper', VueCropper)

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#6200EA',
        secondary: '#c17bd7',
        accent: '#341E97',
        error: '#FF6B6B',
        warning: '#fb6342',
        info: '#18ccee',
        success: '#1CD1A1',
        bgGrey: '#F0F2F5',
        catLA: '#569FFF',
        catLD: '#FF9F43',
        catNO: '#1CD1A1',
        catLR: '#5F27CD',
        pending: '#FFCA28',
        bgApp: '#F0F2F5'
          },
        },
    },
})

Vue.config.productionTip = true

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (!store.getters['auth/loggedIn']) {
      next({ name: 'Login' })
    } else if (to.meta.lawyerAuth) {
      if (localStorage.getItem('isLawyer') === 'false') {
        router.push('/404')
      } else {
        next()
      }
    } else if (to.meta.clientAuth) {
      if (localStorage.getItem('isLawyer') === 'true') {
        router.push('/404')
      } else {
        next()
      }
    } else {
      next()
    }
  } else if (to.meta.guestAuth) {
    if (store.getters['auth/loggedIn']) {
      if (from.path === '/') {
        if (localStorage.getItem('isLawyer') === 'true') {
          next({ name: 'Jobs' })
        } else {
          next({ name: 'Post-Problem' })
        }
      } else if (to.meta.lawyerAuth) {
        if (localStorage.getItem('isLawyer') === true) {
          next()
        } else {
          next(from.path)
        }
      } else {
        next(from.path)
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
