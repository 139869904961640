export default [
  // POST PROBLEM ROUTE
  {
    path: '/post-problem',
    name: 'Post-Problem',
    meta: { requiresAuth: true, clientAuth: true },
    component: () => import('../views/client/PostTask')
  },
  // LAWYERS LIST ROUTE
  {
    path: '/lawyers',
    name: 'Lawyers',
    props: true,
    meta: { requiresAuth: true, clientAuth: true },
    component: () => import('../views/client/FindLawyers')
  }
]
