<template>
  <div>
    <SideToolBar ref="SideBar"/>
    <v-app-bar
      app
      fixed
      color="white"
      class="elevation-3"
      height="64"
    >
      <v-container mx-auto :px-0="$vuetify.breakpoint.smAndDown" py-0>
        <v-layout v-if="!$vuetify.breakpoint.mdAndDown" align-center>
          <v-flex xs1 pt-1>
            <router-link to="/">
              <img
                width="100%"
                src="https://res.cloudinary.com/starupgeek-law/image/upload/v1583571530/assets/txrdl3nqu81mbs7ykxyk.png"
                :to="{ name: 'Home' }"
              />
            </router-link>
          </v-flex>
          <v-flex xs9>
            <v-btn
              text
              class="mr-1"
              to="/how-it-works"
            >How it Works</v-btn>
            <!-- <v-btn
              text
              class="mr-1"
              to="/how-to-pay"
            >How to Pay</v-btn> -->
            <v-btn
              text
              class="mr-1"
              to="/about-us"
            >About Us</v-btn>
            <v-menu offset-y open-on-hover>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  text
                >Information</v-btn>
              </template>
              <v-list class="px-3">
                <v-list-item to="/terms-and-conditions">
                  <v-list-item-title>Terms & Conditions</v-list-item-title>
                </v-list-item>
                <v-list-item to="/privacy-policy">
                  <v-list-item-title>Privacy Policy</v-list-item-title>
                </v-list-item>
                <!-- <v-list-item to="/fees-and-charges">
                  <v-list-item-title>Fees & Charges</v-list-item-title>
                </v-list-item> -->
              </v-list>
            </v-menu>
          </v-flex>
          <v-spacer />
          <v-btn
            v-if="$store.getters['user/user'].user.isLawyer"
            text
            to="/Jobs"
          >Home</v-btn>
          <v-btn
            v-if="!$store.getters['user/user'].user.isLawyer"
            text
            to="/Post-Problem"
          >Home</v-btn>
        </v-layout>
        <v-layout v-else align-center>
          <v-flex xs6 pt-1>
            <router-link to="/">
              <img
                width="100%"
                src="https://res.cloudinary.com/starupgeek-law/image/upload/v1583571530/assets/txrdl3nqu81mbs7ykxyk.png"
                :to="{ name: 'Home' }"
              />
            </router-link>
          </v-flex>
          <v-spacer></v-spacer>
          <v-btn
              pt-0
              icon
              large
              class="primary--text"
              @click="showSideBar"
            >
              <v-icon large>menu</v-icon>
            </v-btn>
        </v-layout>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
import SideToolBar from './Information-Sidebar'

export default {
  components: {
    SideToolBar
  },
  methods: {
    showSideBar () {
      this.$refs.SideBar.showSideBar()
    }
  }
}
</script>

<style scoped>
  .tile {
    margin: 5px;
    border-radius: 4px;
  }
  .tile:hover {
    background: green;
  }
  .tile:active {
    background: yellow;
  }
</style>
