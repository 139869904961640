import BoardService from '@/services/BoardService'

const state = {
  boards: {}
}

const getters = {
  boards (state) {
    return state.boards
  }
}

const mutations = {
  SET_COMPLETED_BOARDS (state, payload) {
    state.boards = payload.boards
  }
}

const actions = {
  GET_COMPLETED_BOARDS ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      BoardService.getCompletedProblems()
        .then(res => {
          if (res.status === 200) {
            commit('SET_COMPLETED_BOARDS', res.data)
            resolve()
          } else {
            reject(res)
          }
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
