<template>
  <div>
    <v-toolbar
      app
      :scroll-off-screen="$vuetify.breakpoint.mdAndDown"
      :scroll-threshold="60"
      color="white"
      class="elevation-3"
      height="64"
    >
      <v-container mx-auto :px-0="$vuetify.breakpoint.smAndDown" py-0>
        <v-layout justify-center align-center>
          <router-link to="/">
            <v-img
              contain
              height="48"
              width="48"
              max-width="48"
              :src="require('@/assets/logo.png')"
              :to="{ name: 'Home' }"
            />
          </router-link>
        </v-layout>
      </v-container>
    </v-toolbar>
    <v-container style="height: 60vh">
      <v-layout align-center justify-center wrap fill-height>
        <v-flex xs12 text-center>
          <p class="display-3 font-weight-bold error--text">Oops!</p>
          <span class="title font-weight-bold">Internal Server Error!</span>
          <p>The server encountered an error and could not process your request.</p>
          <p>Please try again later.</p>
          <v-btn depressed color="primary" @click="goHome">Back to Home</v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  methods: {
    goHome () {
      this.$store.dispatch('errors/setError500', false)
      this.$router.push('/')
    }
  }
}
</script>
