import Api from '@/services/Api'

export default {
  registerAsClient (params) {
    return Api().post('/api/users/r/client', params)
  },
  registerLawyer (params) {
    return Api().post('api/users/r/lawyer', params.formData)
  },
  loginUser (params) {
    return Api().post('/api/users/login', params)
  },
  getCsrfToken () {
    return Api().get('/getCSRF')
  }
}
