<template>
  <v-menu offset-y :close-on-content-click="closed">
    <template v-slot:activator="{ on }">
      <v-badge v-model="notif" :content="notifCount" color="warning" small overlap>
        <v-avatar v-on="on" @click="isNotified()" size="40" color="bgGrey" class="mr-1" style="cursor: pointer;">
          <v-icon color="black">mdi-bell</v-icon>
        </v-avatar>
      </v-badge>
    </template>
    <v-sheet class="scroll" v-if="notifications.length > 0" width="500" max-height="90vh">
      <v-row align="center" class="px-4 mt-4" no-gutters>
        <v-col cols="6">
          <h2>Notifications</h2>
        </v-col>
        <v-col cols="6" class="text-right">
          <v-btn text icon small color="error" @click="clearNotifications()">
            <v-icon>delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="center" class="py-0 px-1" v-for="(notif, index) in notifications" :key="index" no-gutters>
        <v-hover>
          <v-row justify="center" slot-scope="{ hover }" class="tile pointer ma-0 pa-3" no-gutters>
            <v-col cols="1">
              <v-avatar size="38" v-if="notif.notifType === 'Hired Lawyer' || notif.notifType === 'Disputed Lawyer' || notif.notifType === 'Board Reply' || notif.notifType === 'Advice Completed'">
                <img v-if="!isLawyer" :src="notif.client.imageUrl || 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'">
                <img v-if="!notif.problem.isAnonymous && isLawyer" :src="notif.client.imageUrl || 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'">
                <img v-if="notif.problem.isAnonymous && isLawyer" src='https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'>
              </v-avatar>
              <v-avatar size="38" v-if="notif.notifType === 'Legal Advice Proposal'" color="primary">
                <v-icon class="white--text">subject</v-icon>
              </v-avatar>
              <v-avatar size="38" color="success" v-if="notif.notifType === 'Approved Deposit' || notif.notifType === 'Add Credits' || notif.notifType === 'Approved Cashout' || notif.notifType === 'Legal Advice Paid' || notif.notifType === 'Override Task'" >
                <v-icon size="20" class="white--text">check</v-icon>
              </v-avatar>
              <v-avatar size="38" v-if="notif.notifType === 'Denied' || notif.notifType  === 'Denied Credits' || notif.notifType  === 'Denied Cashout'" color="error">
                <v-icon class="white--text">clear</v-icon>
              </v-avatar>
              <v-avatar size="38" v-if="notif.notifType === 'Dispute Award' && notif.awardedTo === 'Complainant' && !isLawyer" color="yellow darken-1">
                <v-icon class="white--text">emoji_events</v-icon>
              </v-avatar>
              <v-avatar size="38" v-if="notif.notifType === 'Dispute Award' && notif.awardedTo === 'Complainant' && isLawyer" color="error">
                <v-icon class="white--text">emoji_events</v-icon>
              </v-avatar>
              <v-avatar size="38" v-if="notif.notifType === 'Dispute Award' && notif.awardedTo === 'Defendant' && isLawyer" color="yellow darken-1">
                <v-icon class="white--text">emoji_events</v-icon>
              </v-avatar>
              <v-avatar size="38" v-if="notif.notifType === 'Dispute Award' && notif.awardedTo === 'Defendant' && !isLawyer" color="error">
                <v-icon class="white--text">emoji_events</v-icon>
              </v-avatar>
              <v-avatar size="38" v-if="notif.notifType === 'Dispute Response'" color="primary">
                <v-icon class="white--text">gavel</v-icon>
              </v-avatar>
            </v-col>
            <v-col cols="10" class="px-2" @click="redirectNotification(notif.notifType, notif.legalAdviceId, notif.disputeId, notif.problem ? notif.problem._id : '')">
              <span v-if="notif.notifType === 'Legal Advice Proposal'" class="black--text text-sm-body-2">You have {{ notif.proposalCount }} proposal{{ notif.proposalCount > 1 ? 's' : '' }} on your <span class="primary--text">Legal Advice</span></span>
              <span v-if="notif.notifType === 'Hired Lawyer' && !notif.problem.isAnonymous" class="text-sm-body-2">You have been hired by <span class="font-weight-bold">{{ notif.client.fullName | slice }}.</span></span>
              <span v-if="notif.notifType === 'Hired Lawyer' && notif.problem.isAnonymous" class="text-sm-body-2">You have been hired by an <span class="font-weight-bold">Anonymous User.</span></span>
              <span v-if="notif.notifType === 'Legal Advice Paid' && !isLawyer" class="text-sm-body-2">Your {{ notif.transaction.amount | phCurrency}} <span class="font-weight-bold">{{ notif.transaction.type }}</span> has been approved.</span>
              <span v-if="notif.notifType === 'Legal Advice Paid' && isLawyer && !notif.problem.isAnonymous" class="text-sm-body-2"><span class="font-weight-bold">{{ notif.client.fullName | slice }}</span> has paid {{ notif.transaction.amount | phCurrency}} as payment for your <span class="font-weight-bold">Legal Advice</span>. This will be forwarded to your wallet as soon as the task is completed. You may now answer his/her question.</span>
              <span v-if="notif.notifType === 'Legal Advice Paid' && isLawyer && notif.problem.isAnonymous" class="text-sm-body-2"><span class="font-weight-bold">The Anonymous User</span> has paid {{ notif.transaction.amount | phCurrency}} as payment for your <span class="font-weight-bold">Legal Advice</span>. This will be forwarded to your wallet as soon as the task is completed. You may now answer his/her question.</span>
              <span v-if="notif.notifType === 'Advice Completed' && !notif.problem.isAnonymous" class="text-sm-body-2"><span class="font-weight-bold">{{ notif.client.fullName | slice }}</span> has rated you and completed the job for <span class="font-weight-bold">{{ notif.transaction.type }}</span>.</span>
              <span v-if="notif.notifType === 'Advice Completed' && notif.problem.isAnonymous" class="text-sm-body-2"><span class="font-weight-bold">Anonymous User</span> has rated you and completed the job for <span class="font-weight-bold">{{ notif.transaction.type }}</span>.</span>
              <span v-if="notif.notifType === 'Approved Deposit'" class="text-sm-body-2">Your {{ notif.transaction.amount | phCurrency}} <span class="font-weight-bold">{{ notif.transaction.type }}</span> has been approved.</span>
              <span v-if="notif.notifType === 'Denied'" class="text-sm-body-2">Your {{ notif.transaction.amount | phCurrency}} <span class="font-weight-bold">{{ notif.transaction.type }}</span> has been denied.</span>
              <span v-if="notif.notifType === 'Add Credits'" class="text-sm-body-2">Your {{ notif.transaction.amount | phCurrency}} <span class="font-weight-bold">{{ notif.transaction.type }}</span> has been approved.</span>
              <span v-if="notif.notifType === 'Denied Credits'" class="text-sm-body-2">Your {{ notif.transaction.amount | phCurrency}} <span class="font-weight-bold">{{ notif.transaction.type }}</span> has been denied.</span>
              <span v-if="notif.notifType === 'Approved Cashout'" class="text-sm-body-2">Your {{ notif.transaction.amount | phCurrency }} <span class="font-weight-bold">Cashout</span> has been approved.</span>
              <span v-if="notif.notifType === 'Denied Cashout'" class="text-sm-body-2">Your {{ notif.transaction.amount | phCurrency }} <span class="font-weight-bold">Cashout</span> has been declined.</span>
              <span v-if="notif.notifType === 'Disputed Lawyer' && !notif.problem.isAnonymous" class="text-sm-body-2"><span class="font-weight-bold">{{ notif.client.fullName | slice }}</span> has filed a dispute.</span>
              <span v-if="notif.notifType === 'Disputed Lawyer' && notif.problem.isAnonymous" class="text-sm-body-2"><span class="font-weight-bold">Anonymous </span> Anonymous has filed a dispute.</span>
              <span v-if="notif.notifType === 'Dispute Response'">The lawyer has responded to your dispute.</span>
              <span v-if="notif.notifType === 'Dispute Award' && notif.awardedTo === 'Complainant' && !isLawyer" class="text-sm-body-2">Our team awarded the <span class="font-weight-bold">Dispute</span> to you.</span>
              <span v-if="notif.notifType === 'Dispute Award' && notif.awardedTo === 'Complainant' && isLawyer" class="text-sm-body-2">Our team awarded the <span class="font-weight-bold">Dispute</span> to the Client.</span>
              <span v-if="notif.notifType === 'Dispute Award' && notif.awardedTo === 'Defendant' && isLawyer" class="text-sm-body-2">Our team awarded the <span class="font-weight-bold">Dispute</span> to you.</span>
              <span v-if="notif.notifType === 'Dispute Award' && notif.awardedTo === 'Defendant' && !isLawyer" class="text-sm-body-2">Your <span class="font-weight-bold">Dispute</span> has been resolved in favor of the lawyer.</span>
              <span v-if="notif.notifType === 'Override Task'" class="text-sm-body-2">Our team has overwritten the Legal Advice. The amount <span class="font-weight-medium">{{ notif.transaction.amount | phCurrency }}</span> has been added to <span>{{ !isLawyer ? 'your lawyer' : 'you' }}</span>.</span>
              <p class="mb-0 caption grey--text">{{ notif.createdAt | momentFromNow }}</p>
            </v-col>
            <v-col cols="1">
              <v-btn v-if="hover" :loading="isDeleting" icon>
                <v-icon
                  small
                  v-on="on"
                  color="error"
                  @click="deleteNotif(notif._id, index)"
                >clear</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-hover>
      </v-row>
    </v-sheet>
    
    <v-sheet v-else width="500">
      <v-row justify="center" class="py-6" no-gutters>
        <v-col cols="12" class="pb-0 text-center">
          <img class="mx-2" width="140" src="@/assets/NoNotifications.png"/>
        </v-col>
        <v-col cols="12" class="text-center pt-0">
          <span class="font-weight-medium grey--text">
            All caught up!
          </span>
        </v-col>
      </v-row>
    </v-sheet>
  </v-menu>
</template>

<script>
import NotificationService from '@/services/NotificationService'
import accountingMixins from '@/mixins/accountingMixins'
import fromNowMixin from '@/mixins/momentFromNowMixin'
import NotificationMixin from '@/mixins/notificationMixins'

export default {
  name: 'Notifications',
  mixins: [
    accountingMixins,
    fromNowMixin,
    NotificationMixin
  ],
  props: {
    socket: {
      required: true
    }
  }
}
</script>

<style scoped>
  .scroll {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .arrow-up {
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid black;
  }
</style>