
const state = {
  hasError500: false
}

const getters = {
  hasError500 (state) {
    return state.hasError500
  }
}

const mutations = {
  SET_ERROR_500 (state, payload) {
    state.hasError500 = payload
  }
}

const actions = {
  setError500 ({ commit }, payload) {
    commit('SET_ERROR_500', payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
