<template>
  <v-footer dark :style="$vuetify.breakpoint.mdAndDown ? 'height:500px; margin-bottom: -500px' : 'height:200px; margin-bottom: -200px'">
    <v-layout row wrap align-center justify-center class="pt-4 pb-3">
      <v-flex xs12 md4 class="text-center">
        <router-link to="/">
          <img width="280" src="@/assets/LawyerkoFooterLogo.png" />
        </router-link>
      </v-flex>
      <v-flex xs12 md2 :class="$vuetify.breakpoint.smAndDown ? 'text-center mb-3' : ''">
        <p class="subheading font-weight-bold mb-0">About</p>
        <router-link to="/about-us"><li><a class="white--text">About Us</a></li></router-link>
        <router-link to="/how-it-works"><li><a class="white--text">How it Works</a></li></router-link>
        <!-- <router-link to="/how-to-pay"><li><a class="white--text">How to Pay</a></li></router-link> -->
      </v-flex>
      <v-flex xs12 md2 :class="$vuetify.breakpoint.smAndDown ? 'text-center mb-3' : ''">
        <p class="subheading font-weight-bold mb-0">Information</p>
        <router-link to="/terms-and-conditions" class="white--text"><li>Terms & Conditions</li></router-link>
        <router-link to="/privacy-policy" class="white--text"><li>Privacy Policy</li></router-link>
        <!-- <router-link to="/fees-and-charges" class="white--text"><li>Fees & Charges</li></router-link> -->
      </v-flex>
      <v-flex xs12 md2 :class="$vuetify.breakpoint.smAndDown ? 'text-center mb-3' : ''">
        <p class="subheading font-weight-bold mb-0">Contact Us</p>
        <li><a class="white--text">+63 956 911 1997</a></li>
        <li><a class="white--text">+63 929 134 2012</a></li>
        <li><a class="white--text">support@lawyerko.com</a></li>
      </v-flex>
      <v-flex xs12 px-5 py-3>
        <v-divider></v-divider>
      </v-flex>
      <v-flex xs12>
        <div class="text-center">
          Copyright @ 2020 LawyerKo. All rights reserved.
        </div>
      </v-flex>
    </v-layout>
  </v-footer>
</template>

<script>
export default {
}
</script>

<style scoped>
  li {
    list-style-type: none;
  }
  a {
    text-decoration: none;
  }
</style>
