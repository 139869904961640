import Api from '@/services/Api'

export default {
  getVerified (params) {
    return Api().patch(`/api/verification/verified/${params.userId}/${params.verificationToken}`)
  },
  resendEmailVerification (params) {
    return Api().post(`/api/verification/resend-email/${params}`)
  }
}
