import Api from '@/services/Api'

export default {
  getPendingJob (params) {
    return Api().get('/api/jobs/' + params.jobId)
  },
  getJobs (params) {
    return Api().get(`/api/jobs/all?page=${params.page}&limit=${params.limit}&title=${params.title}&isAnonymous=${params.isAnonymous}&date=${params.date}`)
  }
}
