export default [
  // TASK ROUTE
  {
    path: '/tasks',
    name: 'Tasks',
    meta: { requiresAuth: true },
    component: () => import('../views/Task')
  },
  // TRANSACTION ROUTE
  {
    path: '/transactions/',
    name: 'Transactions',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('../views/Wallet')
  },
  {
    path: '/addfunds/',
    name: 'Add-Funds',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('../views/AddFunds')
  },
  // LA-BOARD ROUTE
  {
    path: '/board/:boardId',
    name: 'Board',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('../views/boards/LegalAdvice')
  },
  // DISPUTE ROUTE
  {
    path: '/disputes/:disputeId',
    name: 'Dispute-ViewOne',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('../views/dispute/ViewOne')
  },
  // VIEW LAWYER ROUTE
  {
    path: '/lawyer/:lawyerId',
    name: 'ViewLawyer',
    meta: { requiresAuth: true },
    props: true,
    component: () => import('../views/client/ViewLawyer')
  },
  // OPEN JOBS ROUTE
  {
    path: '/jobs/:problemId',
    name: 'OpenJob',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('../views/OpenJob')
  },
  // MOBILE NOTIFICATIONS
  {
    path: '/mobile-notifications',
    name: 'MobileNotifications',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('../components/NavigationComponents/MobileNotifications')
  },
  // MOBILE CONVERSATIONS
  {
    path: '/mobile-conversation/:boardId',
    name: 'MobileConversation',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('@/components/BoardComponents/MobileConversations')
  },
  // PAYPAL ROUTE
  {
    path: '/paypal',
    name: 'Paypal',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('../components/PayPalComponents/TestIndex')
  },
  // SUCCESS PAYPAL ROUTE
  {
    path: '/success',
    name: 'Success',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('../components/PayPalComponents/Success')
  },
  // SUCCESS GOOGLE ROUTE
  {
    path: '/google-success',
    name: 'GoogleSuccess',
    component: () => import('../views/GoogleSuccess')
  }
]
